import React from 'react'
import { Link } from 'react-router-dom'

export default function CardSmall({ category, title, price, image, link }) {

  return (
    <>
      <div className="w-[300px] bg-white m-[15px] shadow-md duration-500 hover:scale-105 hover:shadow-xl">
        <Link to={link}>
          <img src={image} alt="Product" className="w-[300px] object-cover " />
          <div className="w-full flex flex-wrap px-4 py-3 w-[300px]">
            <div className="w-full">
              <span className="text-[#ccc] mr-3 uppercase text-xs">
                {category}
              </span>
            </div>
            <div className="w-full pb-[10px]">
              <h5 className="card-title">{title}</h5>
            </div>
          </div>
        </Link>
      </div>
    </>
  )
}
