import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import BoxInfo from '../../../components/modules/box-info'
import { useLocation } from 'react-router-dom'
import axios from 'axios'

export default function ContactConfirmation() {
  const [done, setDone] = useState(false)
  const { t } = useTranslation()
  const location = useLocation()

  // Function to parse query parameters
  function useQuery() {
    return new URLSearchParams(location.search)
  }

  const query = useQuery()
  const id = query.get('id')

  useEffect(() => {
    if (!done) {
      axios
        .post('https://benjamiin.li/api_proxy.php/newsletter/confirm', {
          code: id,
        })
        .then(() => {
          console.log('Confirmed')
        })
      setDone(true)
    }
  }, [id, done])
  return (
    <>
      <BoxInfo
        icon={t('iconPostboxBlack')}
        title={t('contactConfirmationTitle')}
        content={t('contactConfirmationContent')}
        content2={t('contactConfirmationContent2')}
        cta={undefined}
        ctaLink={undefined} // cta={t('Contact')}
        // ctaLink={`/${i18n.language}/${t('slugContact')}`}
      />
      <h3>{id}</h3>
    </>
  )
}
