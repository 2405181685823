import React  from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import ReturnParameters from '../../functions/return-parameters'
import '../../style/navbar.css'

import i18n from '../../i18n'
import { useThemeContext } from '../../context/theme-context.tsx'

const Navbar = () => {
  
  const { theme, setTheme } = useThemeContext();
  
  const { t } = useTranslation()
  
  const [showNavbar, setShowNavbar] = useState(false)

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar)
  }

  const handleLogOut = (e) => {
    e.preventDefault()
    // setIsLoggedIn(!isLoggedIn)
  }



  return (
    <div id="sticky" className="w-full">
      <nav className="max-w-7xl w-full mx-auto navbar flex items-center">
        <div className="w-full flex items-center justify-between mx-auto">
          <div className="logo">
            <div className="mb-only ps-3">
              <NavLink to={`/${i18n.language}/home`} onClick={ReturnParameters}>
                <img
                  src={
                    'https://enudehac.myhostpoint.ch/assets/logo-benjamiin-regular-white.png'
                  }
                  width={150}
                  alt="..."
                />
              </NavLink>
            </div>
            <div className="dk-only">
              <NavLink to={`/${i18n.language}/home`} onClick={ReturnParameters}>
                <img
                  src={
                    'https://enudehac.myhostpoint.ch/assets/logo-benjamiin-regular-green.png'
                  }
                  width={150}
                  alt="..."
                />
              </NavLink>
            </div>
          </div>
          <div className="flex">
            {/*
            {cartQuantity !== 0 && (
              <div className="w-full flex md:hidden items-center ms-auto">
                <div className="shopping-list">
                  <Link to={`/${i18n.language}/${t('slugShop')}`}>
                    <button className="shopping-icon flex mt-2 me-2" onClick={openCart}>
                      <div
                        className="mr-2"
                        dangerouslySetInnerHTML={{
                          __html: `${t('iconCartWhite')}`,
                        }}
                      ></div>
                      {cartQuantity}
                    </button>
                  </Link>
                </div>
              </div>
            )}
            */}

            <div className="menu-icon" onClick={handleShowNavbar}>
              <button className="bg-secondary me-3 md:me-0">
                <svg
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.75 7C20.75 7.41421 20.4142 7.75 20 7.75L4 7.75C3.58579 7.75 3.25 7.41421 3.25 7C3.25 6.58579 3.58579 6.25 4 6.25L20 6.25C20.4142 6.25 20.75 6.58579 20.75 7Z"
                    fill="#0C2813"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.75 12C20.75 12.4142 20.4142 12.75 20 12.75L4 12.75C3.58579 12.75 3.25 12.4142 3.25 12C3.25 11.5858 3.58579 11.25 4 11.25L20 11.25C20.4142 11.25 20.75 11.5858 20.75 12Z"
                    fill="#0C2813"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.75 17C20.75 17.4142 20.4142 17.75 20 17.75L4 17.75C3.58579 17.75 3.25 17.4142 3.25 17C3.25 16.5858 3.58579 16.25 4 16.25L20 16.25C20.4142 16.25 20.75 16.5858 20.75 17Z"
                    fill="#0C2813"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div
            className={`nav-elements flex flex-wrap align-items-center ${showNavbar && 'active'}`}
          >
            <div>
              <ul>
                {/* 
                <li>
                  <NavLink
                    to={`/${i18n.language}/${t('slugAboutBenjamiin')}`}
                    onClick={handleShowNavbar}
                  >
                    {t('aboutBenjamiin')}
                  </NavLink>
                </li>
                */}
                <li>
                  <NavLink
                    to={`/${i18n.language}/${t('slugStory')}`}
                    onClick={handleShowNavbar}
                    key={t('slugStory')}
                  >
                    {t('story')}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/${i18n.language}/${t('slugRecipe')}`}
                    onClick={handleShowNavbar}
                    key={t('slugRecipe')}
                  >
                    {t('recipe')}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/${i18n.language}/${t('slugApproach')}`}
                    onClick={handleShowNavbar}
                    key={t('slugApproach')}
                  >
                    {t('approach')}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/${i18n.language}/${t('slugAboutUs')}`}
                    onClick={handleShowNavbar}
                    key={t('slugAboutUs')}
                  >
                    {t('aboutUs')}
                  </NavLink>
                </li>
                <li className="mb-only">
                  <NavLink
                    to={`/${i18n.language}/${t('slugContact')}`}
                    onClick={handleShowNavbar}
                    key={t('slugContact')}
                  >
                    {t('contact')}
                  </NavLink>
                </li>
                {/* */}
                <NavLink
                  className="dk-only"
                  to={`/${i18n.language}/under-construction`}
                  onClick={handleShowNavbar}
                  key={t('shop')}
                >
                  <svg height="30px" width="30px" viewBox="0 0 512 512">
                    <g>
                      <path
                        fill="#0C2813"
                        d="M491.615,95.734c-5.06-6.18-12.634-9.772-20.622-9.772H123.664l-11.835-40.657v0.008
		c-5.433-18.176-20.341-31.947-38.883-35.92L30.48,0.3C23.297-1.245,16.217,3.339,14.671,10.53
		c-1.536,7.19,3.04,14.271,10.24,15.807l42.449,9.102c9.032,1.935,16.3,8.642,18.949,17.506l77.597,266.547
		c-1.748,0.815-3.438,1.698-5.068,2.674c-8.362,4.984-15.07,11.98-19.568,20.147c-4.305,7.76-6.589,16.555-6.809,25.631h-0.034
		v1.383h0.034c0.17,7.09,1.681,13.89,4.304,20.096c4.202,9.925,11.181,18.321,19.976,24.264c8.788,5.951,19.476,9.44,30.853,9.432
		h249.568c7.361,0,13.32-5.96,13.32-13.312c0-7.352-5.96-13.312-13.32-13.312l-249.568-0.008c-3.974,0-7.692-0.798-11.105-2.233
		c-5.102-2.156-9.492-5.79-12.565-10.358c-2.954-4.39-4.703-9.56-4.839-15.23c0.152-6.767,2.309-12.642,6.095-17.337
		c1.961-2.42,4.38-4.542,7.318-6.3c2.904-1.732,6.326-3.107,10.341-3.973l250.841-40.828c16.386-2.674,29.426-15.205,32.737-31.481
		l30.682-150.839v-0.016c0.356-1.749,0.527-3.532,0.527-5.298C497.626,106.499,495.536,100.54,491.615,95.734z M440.319,263.442
		c-1.104,5.434-5.451,9.611-10.918,10.494l-239.669,39.02l-58.325-200.361h339.586h0.009L440.319,263.442z"
                      />
                      <path
                        fill="#0C2813"
                        d="M218.411,444.786c-5.85-3.957-12.972-6.291-20.546-6.282c-5.052,0-9.916,1.026-14.314,2.895
		c-6.605,2.793-12.191,7.436-16.156,13.303c-3.964,5.85-6.291,12.973-6.274,20.546c-0.008,5.043,1.019,9.908,2.887,14.306
		c2.801,6.614,7.436,12.2,13.295,16.156c5.858,3.974,12.989,6.292,20.562,6.292c5.043,0,9.9-1.028,14.297-2.895
		c6.614-2.793,12.2-7.446,16.165-13.304c3.956-5.85,6.282-12.981,6.282-20.554c0-5.042-1.036-9.898-2.895-14.305
		C228.913,454.328,224.269,448.742,218.411,444.786z M211.517,481.012c-1.12,2.649-3.014,4.933-5.382,6.529
		c-2.378,1.596-5.162,2.521-8.27,2.53c-2.08-0.009-4.007-0.416-5.765-1.172c-2.64-1.103-4.933-3.005-6.529-5.365
		c-1.596-2.386-2.521-5.179-2.53-8.287c0.009-2.072,0.424-3.99,1.163-5.764c1.112-2.64,3.014-4.924,5.382-6.528
		c2.378-1.596,5.171-2.514,8.278-2.522c2.072,0,3.99,0.417,5.756,1.163c2.649,1.112,4.924,3.006,6.52,5.383
		c1.605,2.377,2.53,5.162,2.53,8.268C212.672,477.327,212.256,479.246,211.517,481.012z"
                      />
                      <path
                        fill="#0C2813"
                        d="M419.943,444.786c-5.858-3.957-12.98-6.291-20.553-6.282c-5.043,0-9.908,1.026-14.306,2.895
		c-6.613,2.793-12.2,7.436-16.164,13.303c-3.956,5.85-6.282,12.973-6.282,20.546c0,5.043,1.036,9.908,2.895,14.306
		c2.793,6.614,7.446,12.2,13.304,16.165c5.85,3.965,12.981,6.283,20.554,6.283c5.042,0,9.907-1.028,14.305-2.895
		c6.605-2.793,12.191-7.446,16.156-13.304c3.964-5.85,6.29-12.981,6.282-20.554c0-5.042-1.027-9.898-2.895-14.305
		C430.436,454.328,425.801,448.742,419.943,444.786z M413.041,481.012c-1.113,2.649-3.014,4.933-5.374,6.529
		c-2.377,1.596-5.179,2.521-8.277,2.53c-2.08-0.009-3.999-0.416-5.765-1.172c-2.649-1.103-4.924-3.005-6.52-5.365
		c-1.613-2.386-2.53-5.179-2.53-8.287c0-2.072,0.408-3.99,1.155-5.764c1.12-2.64,3.013-4.924,5.382-6.528
		c2.377-1.596,5.17-2.514,8.278-2.522c2.08,0,3.998,0.417,5.764,1.163c2.641,1.112,4.925,3.006,6.52,5.383
		c1.596,2.377,2.522,5.162,2.531,8.268C414.204,477.327,413.788,479.246,413.041,481.012z"
                      />
                      <path
                        fill="#0C2813"
                        d="M209.718,142.021c-2.173-7.48-10.001-11.768-17.481-9.586c-7.479,2.182-11.758,10.01-9.584,17.481
		l36.523,125.055c2.19,7.471,10.018,11.767,17.489,9.577c7.471-2.174,11.767-10.01,9.577-17.489L209.718,142.021z"
                      />
                      <path
                        fill="#0C2813"
                        d="M271.388,141.783c-2.173-7.479-10.001-11.767-17.48-9.593c-7.48,2.19-11.767,10.018-9.585,17.489
		l33.874,115.988c2.19,7.48,10.018,11.767,17.489,9.585c7.471-2.182,11.767-10.009,9.577-17.489L271.388,141.783z"
                      />
                      <path
                        fill="#0C2813"
                        d="M333.066,141.546c-2.182-7.48-10.01-11.768-17.48-9.577c-7.48,2.174-11.767,10.01-9.585,17.48l31.302,107.193
		c2.182,7.48,10.018,11.767,17.489,9.586c7.471-2.182,11.767-10.019,9.576-17.49L333.066,141.546z"
                      />
                      <path
                        fill="#0C2813"
                        d="M394.728,141.298c-2.173-7.479-10.009-11.758-17.489-9.584c-7.471,2.191-11.758,10.018-9.577,17.489
		l28.56,97.812c2.191,7.48,10.018,11.767,17.489,9.585c7.471-2.19,11.767-10.009,9.585-17.489L394.728,141.298z"
                      />
                    </g>
                  </svg>
                </NavLink>
                {/*
                {cartQuantity !== 0 && (
                  <li className="shopping-list">
                    <NavLink
                      to={`/${i18n.language}/${t('slugShop')}`}
                      onClick={handleShowNavbar}
                      key={t('slugShop')}
                    >
                      <button
                        className="shopping-icon hidden md:block dk-only"
                        onClick={openCart}
                      >
                        {cartQuantity}
                      </button>
                    </NavLink>
                  </li>
                )}
                */}
              </ul>
            </div>
            {true ? (
              <div className="dk-only">
                <button
                  className="button-cta bg-five ms-[30px]"
                  onClick={handleLogOut}
                >
                  <div>
                    <svg width="20px" height="20px" viewBox="0 0 20 20">
                      <path
                        fill="#fff"
                        d="M9.76076555,0 C15.4157386,0 20,4.4771525 20,10 C20,15.5228475 15.4157386,20 9.76076555,20 C6.56885647,20 3.61836948,18.5634688 1.68988581,16.1544725 C1.46202241,15.8698333 1.51356853,15.4586837 1.80501731,15.2361442 C2.09646608,15.0136047 2.51745178,15.0639465 2.74531518,15.3485857 C4.4225344,17.443711 6.98554674,18.6915888 9.76076555,18.6915888 C14.6758356,18.6915888 18.6602871,14.8002319 18.6602871,10 C18.6602871,5.19976806 14.6758356,1.30841121 9.76076555,1.30841121 C7.02601512,1.30841121 4.49642844,2.51988396 2.81675903,4.5633425 C2.58516542,4.84509553 2.16355149,4.89014431 1.87505796,4.66396176 C1.58656443,4.43777922 1.54043793,4.02601608 1.77203154,3.74426305 C3.70333647,1.39466883 6.61544133,0 9.76076555,0 Z M10.3053281,6.86239745 L13.0119569,9.56902627 C13.2735521,9.83062149 13.2785069,10.2497964 13.0230237,10.5052795 L10.3796339,13.1486694 C10.1241507,13.4041526 9.70497582,13.3991978 9.4433806,13.1376026 C9.18178539,12.8760073 9.1768306,12.4568325 9.43231378,12.2013493 L10.98,10.6534046 L0.669856459,10.6542056 C0.299904952,10.6542056 7.72715225e-14,10.3613078 7.72715225e-14,10 C7.72715225e-14,9.63869222 0.299904952,9.34579439 0.669856459,9.34579439 L10.938,9.34540456 L9.38014161,7.78758389 C9.11854639,7.52598867 9.11359161,7.1068138 9.36907479,6.85133062 C9.62455797,6.59584744 10.0437328,6.60080223 10.3053281,6.86239745 Z"
                      />
                    </svg>
                  </div>
                  <div className="ms-[15px] text-white">{t('Logout')}</div>
                </button>
              </div>
            ) : (
              <div className="dk-only">
                <button className="button-cta bg-primary ms-[30px]">
                  <div>
                    <svg width="20px" height="20px" viewBox="0 0 20 20">
                      <path
                        fill="#fff"
                        d="M9.76076555,0 C15.4157386,0 20,4.4771525 20,10 C20,15.5228475 15.4157386,20 9.76076555,20 C6.56885647,20 3.61836948,18.5634688 1.68988581,16.1544725 C1.46202241,15.8698333 1.51356853,15.4586837 1.80501731,15.2361442 C2.09646608,15.0136047 2.51745178,15.0639465 2.74531518,15.3485857 C4.4225344,17.443711 6.98554674,18.6915888 9.76076555,18.6915888 C14.6758356,18.6915888 18.6602871,14.8002319 18.6602871,10 C18.6602871,5.19976806 14.6758356,1.30841121 9.76076555,1.30841121 C7.02601512,1.30841121 4.49642844,2.51988396 2.81675903,4.5633425 C2.58516542,4.84509553 2.16355149,4.89014431 1.87505796,4.66396176 C1.58656443,4.43777922 1.54043793,4.02601608 1.77203154,3.74426305 C3.70333647,1.39466883 6.61544133,0 9.76076555,0 Z M10.3053281,6.86239745 L13.0119569,9.56902627 C13.2735521,9.83062149 13.2785069,10.2497964 13.0230237,10.5052795 L10.3796339,13.1486694 C10.1241507,13.4041526 9.70497582,13.3991978 9.4433806,13.1376026 C9.18178539,12.8760073 9.1768306,12.4568325 9.43231378,12.2013493 L10.98,10.6534046 L0.669856459,10.6542056 C0.299904952,10.6542056 7.72715225e-14,10.3613078 7.72715225e-14,10 C7.72715225e-14,9.63869222 0.299904952,9.34579439 0.669856459,9.34579439 L10.938,9.34540456 L9.38014161,7.78758389 C9.11854639,7.52598867 9.11359161,7.1068138 9.36907479,6.85133062 C9.62455797,6.59584744 10.0437328,6.60080223 10.3053281,6.86239745 Z"
                      />
                    </svg>
                  </div>
                  <div className="ms-[15px]">
                    <NavLink
                      className="text-white"
                      to={`/${i18n.language}/under-construction`}
                      onClick={handleShowNavbar}
                      key={t('slugLogin')}
                    >
                      {t('Login')}
                    </NavLink>
                  </div>
                </button>
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Navbar

