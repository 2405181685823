import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function ShowcaseHorizontal({
  bgcolor,
  titleColor,
  contentColor,
  icon1,
  title1,
  content1,
  cta1,
  ctaLink1,
  icon2,
  title2,
  content2,
  cta2,
  ctaLink2,
  icon3,
  title3,
  content3,
  cta3,
  ctaLink3,
  icon4,
  title4,
  content4,
  cta4,
  ctaLink4,
}) {
  const { t } = useTranslation()

  return (
    <>
      <div
        id="modul-ShowcaseHorizontal"
        className="max-w-6xl mx-auto px-3 md:px-0 xl:px-0 mb-[80px]"
      >
        <div className="mt-[15px] grid divide-x-3 divide-y-3  divide-white overflow-hidden border sm:grid-cols-2 lg:grid-cols-4  lg:divide-y-0 xl:grid-cols-4">
          <div
            className="group relative transition hover:z-[1] shadow-2xl  hover:shadow-gray-600/10"
            style={{ backgroundColor: bgcolor }}
          >
            <div className="relative space-y-4 py-6 p-8">
              <div
                className="flex justify-center"
                dangerouslySetInnerHTML={{ __html: icon1 }}
              ></div>
              <div className="space-y-2 text-center">
                <h5
                  className="transition group-hover:text-secondary"
                  style={{ color: titleColor, marginBottom: 10 }}
                >
                  {title1}
                </h5>
                {content1}
                {ctaLink1 && (
                  <div className=" mt-[35px]">
                    <Link to={ctaLink1}>
                      <button className="primary-btn">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t('iconChevronWhite'),
                          }}
                        ></div>
                      </button>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="group relative transition hover:z-[1] shadow-2xl  hover:shadow-gray-600/10"
            style={{ backgroundColor: bgcolor }}
          >
            <div className="relative space-y-4 py-6 p-8">
              <div
                className="flex justify-center"
                dangerouslySetInnerHTML={{ __html: icon2 }}
              ></div>
              <div className="space-y-2 text-center">
                <h5
                  className="transition group-hover:text-secondary"
                  style={{ color: titleColor, marginBottom: 10 }}
                >
                  {title2}
                </h5>
                {content2}
                {ctaLink2 && (
                  <div className=" mt-[25px]">
                    <Link to={ctaLink2}>
                      <button className="primary-btn">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t('iconChevronWhite'),
                          }}
                        ></div>
                      </button>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="group relative transition hover:z-[1] shadow-2xl  hover:shadow-gray-600/10"
            style={{ backgroundColor: bgcolor }}
          >
            <div className="relative space-y-4 py-6 p-8">
              <div
                className="flex justify-center"
                dangerouslySetInnerHTML={{ __html: icon3 }}
              ></div>
              <div className="space-y-2 text-center">
                <h5
                  className="transition group-hover:text-secondary"
                  style={{ color: titleColor, marginBottom: 10 }}
                >
                  {title3}
                </h5>
                {content3}
                {ctaLink3 && (
                  <div className=" mt-[35px]">
                    <Link to={ctaLink3}>
                      <button className="primary-btn">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t('iconChevronWhite'),
                          }}
                        ></div>
                      </button>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="group relative transition hover:z-[1] shadow-2xl  hover:shadow-gray-600/10"
            style={{ backgroundColor: bgcolor }}
          >
            <div className="relative space-y-4 py-6 p-8">
              <div
                className="flex justify-center"
                dangerouslySetInnerHTML={{ __html: icon4 }}
              ></div>
              <div className="space-y-2 text-center">
                <h5
                  className="transition group-hover:text-secondary"
                  style={{ color: titleColor, marginBottom: 10 }}
                >
                  {title4}
                </h5>
                {content4}
                {ctaLink4 && (
                  <div className=" mt-[35px]">
                    <Link to={ctaLink4}>
                      <button className="primary-btn">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t('iconChevronWhite'),
                          }}
                        ></div>
                      </button>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
