import React from 'react'
import Hero from '../../../components/modules/hero'
import ProfileBody from '../../../components/modules/profile-body'

export default function Profile() {
  return (
    <>
      <Hero
        background={
          'https://static.az-cdn.ch/__ip/4orQyRayCrREMuhFrpIwsdbYEvw/c7f40a41ae7092038fb01c7330399d6131dcc88e/n-large-4x3-far'
        }
        title={undefined}
        content={undefined}
        cta={undefined}
        ctaLink={undefined}
        ctaAlt={undefined}
        ctaAltLink={undefined}
      />

      <div className="max-w-7xl flex-wrap mx-auto w-full mb-[100px] sm:p-0">
        <ProfileBody
          title={'Silvia Triebl'}
          content={`I found this pancake recipe in my Grandma's recipe book. Judging from the weathered look of this recipe card, this was a family favorite.`}
          image={
            'https://images.pexels.com/photos/11823968/pexels-photo-11823968.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
          }
          abc={'1'}
          id={undefined}
        />
      </div>
    </>
  )
}
