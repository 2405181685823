import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import { useState } from 'react'
import i18n from '../../i18n'
import 'boxicons'
import '../../style/navigation.css'

const NavigationMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const OpenMenu = () => {
    setIsMenuOpen(!isMenuOpen)
    console.log(isMenuOpen)
  }

  return (
    <div id="" className="w-full">
      <header className="flex justify-between items-center py-6 px-8 md:px-32  bg-color3 bg-opacity-30 drop-shadow-md">
        <Link to={`/${i18n.language}/home`}>
          <img
            src={
              'https://enudehac.myhostpoint.ch/assets/logo-benjamiin-regular-green.png'
            }
            width={150}
            alt="..."
          />
        </Link>

        <ul className="hidden md:flex items-center gap-12 font-semibold text-base">
          <li
            className="p-3 hover:bg-color1 hover:text-white rounded-md transition-all cursor-pointer"
            key="1"
          >
            <NavLink to={`/${i18n.language}/home`}>Item 1</NavLink>
          </li>
          <li
            className="p-3 hover:bg-color1 hover:text-white rounded-md transition-all cursor-pointer"
            key="2"
          >
            Item 2
          </li>
          <li
            className="p-3 hover:bg-color1 hover:text-white rounded-md transition-all cursor-pointer"
            key="3"
          >
            Item 3
          </li>
          <li
            className="p-3 hover:bg-color1 hover:text-white rounded-md transition-all cursor-pointer"
            key="4"
          >
            Item 4
          </li>
        </ul>
        <div className="relative hidden md:flex items-center justify-center gap-3">
          <i className="bx bx-user"></i>
          <i className="bx bxs-user"></i>
          <i className="bx bxl-facebook-square"></i>
        </div>

        <button onClick={OpenMenu}>
          <i className="bx bx-menu block text-5xl cursor-pointer md:hidden"></i>
        </button>

        <div
          className={`absolute md:hidden top-[100px] left-0 w-full bg-white flex flex-col items-center gap-6 font-semibold text-lg transform transition-transform ${isMenuOpen ? 'opacity-1' : 'opacity-0'}`}
          style={{ transition: 'transform 0.3s ease, opacity 0.3s ease' }}
        >
          <div className="h-full w-full bg-color1">
            <ul className="w-full h-full">
              <li
                key="1"
                className="list-none w-full text-center p-4 hover:bg-sky-400 hover:text-white transition-all cursor-pointer"
              >
                Item 1
              </li>
              <li
                key="2"
                className="list-none w-full text-center p-4 hover:bg-sky-400 hover:text-white transition-all cursor-pointer"
              >
                Item 2
              </li>
              <li
                key="3"
                className="list-none w-full text-center p-4 hover:bg-sky-400 hover:text-white transition-all cursor-pointer"
              >
                Item 3
              </li>
              <li
                key="4"
                className="list-none w-full text-center p-4 hover:bg-sky-400 hover:text-white transition-all cursor-pointer"
              >
                Item 4
              </li>
            </ul>
          </div>
        </div>
      </header>
    </div>
  )
}

export default NavigationMenu
