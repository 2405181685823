import React from 'react'
import { Navigate } from 'react-router-dom'
import i18n from '../../i18n'

const AuthGuard = ({ children }) => {
  // if (appContextDetails.session.logged === false) {
  //   return <Navigate to={`/${i18n.language}/auth/login`} />
  // }

  return children
}

export default AuthGuard
