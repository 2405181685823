/*
https://geolocation-db.com/json/0daad5e0-82e7-11ee-92e0-f5d620c7dcb4
https://www.youtube.com/watch?v=je3FTTunyp4&ab_channel=PedroTech
*/


export default function ReturnParameters() {/*
  const [locale, setLocale] = useState(i18n.language)
  const [countryCodeIP, setCountryCodeIP] = useState('')

  const urlbase = window.location.href.replace(/\/$/, '')
  const [url, setUrl] = useState(urlbase)

  let urlcheck2 = urlbase.includes('?')
  const [urlparams, setUrlparams] = useState(urlcheck2)

  let urlcheck3 = urlbase.includes('localhost')
  if ((urlcheck3 = true)) {
    if (urlparams > 0) {
      var path = urlbase.substring('25', 100000)
      var slug = path.split('?')[0]
      var parameters = path.split('?')[1]
      var urlFinal = `https://benjamiin.com/${i18n.language}/${slug}?${parameters}`
      var type = slug.split('/')[0]
      if (type == 'product' || type == 'recipe') {
        var product = slug.substr(slug.length - 4)
      }
      if (type == 'profile') {
        var product = slug.substr(slug.length - 7)
      }
      if (slug.length < 3) {
        Cookies.set('benji-country', `${slug}`, {
          expires: 365,
        })
      }
    } else {
      var slug = urlbase.substring('25', 100000)
      var urlFinal = `https://benjamiin.com/${i18n.language}/${slug}`
      var type = slug.split('/')[0]
      if (type == 'product' || type == 'recipe') {
        var product = slug.substr(slug.length - 4)
      }
      if (type == 'profile') {
        var product = slug.substr(slug.length - 7)
      }
      if (slug.length < 3) {
        Cookies.set('benji-country', `${slug}`, {
          expires: 365,
        })
      }
      if (slug.length != 2) {
        Axios.get(
          'https://geolocation-db.com/json/0daad5e0-82e7-11ee-92e0-f5d620c7dcb4'
        ).then((res) => {
          setCountryCodeIP(res.data.country_code)
          Cookies.set('benji-country', `${countryCodeIP}`, {
            expires: 365,
          })
        })
      }
    }
  }

  return (
    <>
      {i18n.language === 'csdcsd' && (
        <table className="m-[15px] shadow-md">
          <tr>
            <td className="bg-[#efefef] p-2 font-bold">i18n.language</td>
            <td className="p-2">{i18n.language}</td>
          </tr>
          <tr>
            <td className="bg-[#efefef] p-2 font-bold">url</td>
            <td className="p-2">{urlFinal}</td>
          </tr>
          <tr>
            <td className="bg-[#efefef] p-2 font-bold">slug</td>
            <td className="p-2">{slug}</td>
          </tr>
          {parameters && (
            <tr>
              <td className="bg-[#efefef] p-2 font-bold">parameters</td>
              <td className="p-2">{parameters}</td>
            </tr>
          )}
          {type && (
            <tr>
              <td className="bg-[#efefef] p-2 font-bold">type</td>
              <td className="p-2">{type}</td>
            </tr>
          )}
          {product && (
            <tr>
              <td className="bg-[#efefef] p-2 font-bold">product</td>
              <td className="p-2">{product}</td>
            </tr>
          )}
        </table>
      )}
    </>
  )
*/}
