import React from 'react'
import { Link } from 'react-router-dom'
/* import { useState } from 'react'
import Cookies from 'js-cookie' */

export default function Subheader() {
  /*const [countryCode] = useState(Cookies.get('benji-country'))*/

  return (
    <>
      <div id="Subheader" className="hidden md:flex">
        <div className="max-w-7xl mx-auto w-full flex justify-between pt-3">
          <div className="flex align-items-center text-sm">
            {/* 
            <div className="">
              <svg
                version="1.1"
                id="LOCATION"
                width="30px"
                height="30px"
                viewBox="0 0 1800 1800"
              >
                <g>
                  <g>
                    <path
                      fill="#0C2813"
                      d="M899.993,1556.267l441.512-441.511c8.202-7.819,26.127-26.384,26.893-27.184l0.36-0.383
			c110.946-118.997,172.046-274.141,172.046-436.851c0-353.342-287.463-640.805-640.803-640.805
			c-353.342,0-640.805,287.463-640.805,640.805c0,162.714,61.1,317.857,172.038,436.851L899.993,1556.267z M900.001,71.159
			c319.355,0,579.179,259.818,579.179,579.18c0,146.968-55.159,287.114-155.315,394.639c-5.202,5.387-19.292,19.873-25.095,25.383
			L900.006,1469.1l-424.049-424.315C375.902,937.286,320.82,797.229,320.82,650.339C320.82,330.977,580.634,71.159,900.001,71.159z"
                    />
                  </g>
                  <g>
                    <path
                      fill="#0C2813"
                      d="M998.745,225.279c110.577,0,325.781,120.91,325.781,342.553c0,17.018,13.789,30.812,30.812,30.812
			c17.014,0,30.812-13.794,30.812-30.812c0-115.37-50.989-222.331-143.563-301.184c-73.464-62.566-169.175-102.994-243.842-102.994
			c-17.014,0-30.812,13.794-30.812,30.813S981.731,225.279,998.745,225.279z"
                    />
                  </g>
                  <g>
                    <path
                      fill="#0C2813"
                      d="M1286.716,1361.056c-24.003-9.809-49.854-18.548-77.134-26.264l-50.474,50.478
			c148.765,35.502,240.488,98.79,240.488,157.599c0,87.962-205.171,185.974-499.596,185.974
			c-294.417,0-499.597-98.012-499.597-185.974c0-58.805,91.723-122.097,240.488-157.599l-50.478-50.478
			c-27.271,7.716-53.126,16.455-77.121,26.264c-112.537,45.995-174.513,110.563-174.513,181.813s61.977,135.817,174.513,181.813
			c103.793,42.422,241.128,65.785,386.708,65.785c145.582,0,282.921-23.363,386.715-65.785
			c112.536-45.995,174.504-110.563,174.504-181.813S1399.252,1407.051,1286.716,1361.056z"
                    />
                  </g>
                  <g>
                    <path
                      fill="#0C2813"
                      d="M901.771,945.221c-171.172,0-310.434-139.256-310.434-310.425S730.599,324.37,901.771,324.37
			c171.172,0,310.434,139.256,310.434,310.425S1072.943,945.221,901.771,945.221z M901.771,385.995
			c-137.193,0-248.809,111.612-248.809,248.801s111.616,248.801,248.809,248.801c137.192,0,248.809-111.612,248.809-248.801
			S1038.964,385.995,901.771,385.995z"
                    />
                  </g>
                </g>
              </svg>
            </div>
            */}
            {/* 
            countryCode !== '' && (
              <div className="ms-[10px] flex align-items-center">
                {t(`${countryCode}`)}
              </div>
            )
            */}
          </div>

          <div className="hidden sm:flex justify-between">
            <div className="flex items-center">
              <div className="bg-icon">
                <Link className="" to="https://instagram.com">
                  <svg
                    width="20px"
                    height="20px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
                      fill="#fff"
                    />
                    <path
                      d="M18 5C17.4477 5 17 5.44772 17 6C17 6.55228 17.4477 7 18 7C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5Z"
                      fill="#fff"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.65396 4.27606C1 5.55953 1 7.23969 1 10.6V13.4C1 16.7603 1 18.4405 1.65396 19.7239C2.2292 20.8529 3.14708 21.7708 4.27606 22.346C5.55953 23 7.23969 23 10.6 23H13.4C16.7603 23 18.4405 23 19.7239 22.346C20.8529 21.7708 21.7708 20.8529 22.346 19.7239C23 18.4405 23 16.7603 23 13.4V10.6C23 7.23969 23 5.55953 22.346 4.27606C21.7708 3.14708 20.8529 2.2292 19.7239 1.65396C18.4405 1 16.7603 1 13.4 1H10.6C7.23969 1 5.55953 1 4.27606 1.65396C3.14708 2.2292 2.2292 3.14708 1.65396 4.27606ZM13.4 3H10.6C8.88684 3 7.72225 3.00156 6.82208 3.0751C5.94524 3.14674 5.49684 3.27659 5.18404 3.43597C4.43139 3.81947 3.81947 4.43139 3.43597 5.18404C3.27659 5.49684 3.14674 5.94524 3.0751 6.82208C3.00156 7.72225 3 8.88684 3 10.6V13.4C3 15.1132 3.00156 16.2777 3.0751 17.1779C3.14674 18.0548 3.27659 18.5032 3.43597 18.816C3.81947 19.5686 4.43139 20.1805 5.18404 20.564C5.49684 20.7234 5.94524 20.8533 6.82208 20.9249C7.72225 20.9984 8.88684 21 10.6 21H13.4C15.1132 21 16.2777 20.9984 17.1779 20.9249C18.0548 20.8533 18.5032 20.7234 18.816 20.564C19.5686 20.1805 20.1805 19.5686 20.564 18.816C20.7234 18.5032 20.8533 18.0548 20.9249 17.1779C20.9984 16.2777 21 15.1132 21 13.4V10.6C21 8.88684 20.9984 7.72225 20.9249 6.82208C20.8533 5.94524 20.7234 5.49684 20.564 5.18404C20.1805 4.43139 19.5686 3.81947 18.816 3.43597C18.5032 3.27659 18.0548 3.14674 17.1779 3.0751C16.2777 3.00156 15.1132 3 13.4 3Z"
                      fill="#fff"
                    />
                  </svg>
                </Link>
              </div>
              <div>
                <Link
                  className="bg-icon"
                  to="https://www.youtube.com/@Benjamiin4kids"
                >
                  <svg
                    fill="#fff"
                    width="20px"
                    height="20px"
                    viewBox="0 -4 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMid"
                  >
                    <path d="M30.722,20.579 C30.137,21.894 28.628,23.085 27.211,23.348 C27.066,23.375 23.603,24.000 16.010,24.000 L15.990,24.000 C8.398,24.000 4.932,23.375 4.788,23.349 C3.371,23.085 1.861,21.894 1.275,20.578 C1.223,20.461 0.001,17.647 0.001,12.000 C0.001,6.353 1.223,3.538 1.275,3.421 C1.861,2.105 3.371,0.915 4.788,0.652 C4.932,0.625 8.398,-0.000 15.990,-0.000 C23.603,-0.000 27.066,0.625 27.210,0.651 C28.628,0.915 30.137,2.105 30.723,3.420 C30.775,3.538 32.000,6.353 32.000,12.000 C32.000,17.647 30.775,20.461 30.722,20.579 ZM28.893,4.230 C28.581,3.529 27.603,2.759 26.845,2.618 C26.813,2.612 23.386,2.000 16.010,2.000 C8.615,2.000 5.185,2.612 5.152,2.618 C4.394,2.759 3.417,3.529 3.104,4.234 C3.094,4.255 2.002,6.829 2.002,12.000 C2.002,17.170 3.094,19.744 3.106,19.770 C3.417,20.471 4.394,21.241 5.153,21.382 C5.185,21.388 8.615,22.000 15.990,22.000 L16.010,22.000 C23.386,22.000 26.813,21.388 26.846,21.382 C27.604,21.241 28.581,20.471 28.894,19.766 C28.904,19.744 29.998,17.170 29.998,12.000 C29.998,6.830 28.904,4.255 28.893,4.230 ZM13.541,17.846 C13.379,17.949 13.193,18.000 13.008,18.000 C12.842,18.000 12.676,17.959 12.525,17.875 C12.206,17.699 12.008,17.364 12.008,17.000 L12.008,7.000 C12.008,6.637 12.204,6.303 12.521,6.127 C12.838,5.950 13.227,5.958 13.534,6.149 L21.553,11.105 C21.846,11.286 22.026,11.606 22.027,11.951 C22.028,12.296 21.852,12.618 21.560,12.801 L13.541,17.846 ZM14.009,8.794 L14.009,15.189 L19.137,11.963 L14.009,8.794 Z" />
                  </svg>
                </Link>
              </div>
              <div className="pt-0">
                <Link
                  className="bg-icon"
                  to="https://www.linkedin.com/company/benjamiin4kids/"
                >
                  <svg
                    version="1.1"
                    width="20px"
                    height="20px"
                    viewBox="0 0 32 32"
                    enableBackground="new 0 0 32 32"
                  >
                    <g>
                      <path
                        fill="#fff"
                        d="M19.515,11.952c-0.678,0-1.18,0.171-1.573,0.387c-0.063-0.224-0.27-0.388-0.514-0.339h-2.984
		c-0.295-0.049-0.534,0.19-0.443,0.5v11c-0.091,0.28,0.148,0.52,0.443,0.5h2.984c0.295,0.02,0.534-0.22,0.534-0.515l-0.001-6.648
		c-0.001-0.003-0.012-0.321,0.189-0.54c0.161-0.174,0.438-0.263,0.821-0.263c0.638,0,0.922,0.261,1.028,0.845v6.606
		C19.894,23.78,20.133,24.02,20.428,24h3.145c0.295,0.02,0.534-0.22,0.428-0.515v-6.828C24.106,13.185,21.633,11.952,19.515,11.952z
		 M23.038,23h-2.076v-6.121c0-1.181-0.763-1.913-1.99-1.913c-0.694,0-1.234,0.204-1.606,0.606C16.849,16.132,16.889,16.842,17,17v6
		h-2.022v-9.98h1.916v0.336c0,0.203,0.115,0.39,0.298,0.479c0.183,0.089,0.4,0.067,0.562-0.056l0.14-0.108
		c0.405-0.319,0.824-0.65,1.622-0.65c0.826,0,3.523,0.263,3.523,3.637V23z"
                      />
                      <path
                        fill="#fff"
                        d="M11,6.966C9.878,6.966,8.966,7.878,8.966,9S9.878,11.034,11,11.034S13.034,10.122,13.034,9
		S12.122,6.966,11,6.966z M11,9.966c-0.532,0-0.966-0.434-0.966-0.966S10.468,8.034,11,8.034S11.966,8.468,11.966,9
		S11.532,9.966,11,9.966z"
                      />
                      <path
                        fill="#fff"
                        d="M12.428,11.951H9.46c-0.295,0-0.534,0.239-0.46,0.549v11c-0.074,0.28,0.165,0.52,0.46,0.5h2.968
		c0.295,0.02,0.534-0.22,0.534-0.515v-11C12.962,12.19,12.723,11.951,12.428,11.951z M12,23H9.994v-9.98H12V23z"
                      />
                      <path
                        fill="#fff"
                        d="M16-0.034C7.158-0.034-0.034,7.158-0.034,16S7.158,32.034,16,32.034S32.034,24.842,32.034,16
		S24.842-0.034,16-0.034z M16,30.966C7.748,30.966,1.034,24.252,1.034,16S7.748,1.034,16,1.034S30.966,7.748,30.966,16
		S24.252,30.966,16,30.966z"
                      />
                    </g>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
