import React from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'

import Hero from '../../../components/modules/hero'
import AdvertHorizontal from '../../../components/modules/advert-horizontal'
import BlockTextImage from '../../../components/modules/block-text-image'

export default function Books() {
  const { t } = useTranslation()

  return (
    <>
      <Hero
        title={`Benjamiin, seine neue Welt`}
        content={`
              Die Geschichte beginnt an einem verschneiten November-Abend. An jenem Abend habe ich auf mein Patenkind Leandro aufgepasst. Zusammen haben wir Nachrichten im Fernsehen geschaut und Leandro hat mich auf die alltäglichen und leider auch traurigen Themen, über die berichtet wurde, angesprochen. 
              `}
        cta={'Jetzt bestellen'}
        ctaLink={`/${i18n.language}/shop`}
        ctaAlt={'ctaAlt - Alt Button'}
        background={
          'https://benjamiin.com/uploads/images/merchandising/Benjamiin-merchandising-12052024.jpeg'
        }
        ctaAltLink={undefined}
      />

      <div className="max-w-6xl flex-wrap mx-auto w-full my-[0px] sm:p-4">
        <BlockTextImage
          title={`Why writing this kind of books?`}
          content={`
<p>
Ganz bestimmt fühlen sich viele Elternteile von dieser Situation angesprochen, wenn es ums Kochen mit Gemüse geht. In meinem Buch verpacke ich viele wichtige und bewegende Lebensthemen, die von mir charmant und mit Leichtigkeit in die Kinderwelt übertragen werden.</p>
<p>
In dieser ersten Geschichte von Benjamiin für Kinder von 4 – 10 Jahren begleiten die Kinder den kleinen Vampirjungen bei seinen Abenteuern und lernen seine etwas verrückte, aber sehr liebenswerte Familie kennen. Die leckeren und kinderleichten Gemüserezepte von Papa Ruppert laden zum Nachkochen ein und wecken schon bei den Kleinen grosse Freude am Kochen, Probieren und natürlich Essen.
</p>
              `}
          cta={t('contact')}
          ctaLink={`/${i18n.language}/contact`}
          image={
            'https://benjamiin.com/uploads/images/merchandising/Benjamiin-merchandising-12052024.jpeg'
          }
          ctaAlt={undefined}
          ctaAltLink={undefined}
        />

        <AdvertHorizontal
          image={undefined}
          name={undefined}
          title={undefined}
          content={undefined}
        />
      </div>
    </>
  )
}
