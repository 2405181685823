import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

export default function Contact() {
  const { t } = useTranslation()

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    telephone: '',
    email: '',
    comment: '',
    confirm: false,
    subscribe: false,
  })

  const [errors, setErrors] = useState({})

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    })
  }

  const validateForm = () => {
    const newErrors = {}

    if (!formData.firstName) newErrors.firstName = t('fieldRequired')
    if (!formData.lastName) newErrors.lastName = t('fieldRequired')
    if (!formData.email) newErrors.email = t('fieldRequired')
    if (!formData.comment) newErrors.comment = t('fieldRequired')
    if (!formData.confirm) newErrors.confirm = t('fieldRequired')

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = () => {
    if (!validateForm()) return

    axios
      .post('https://benjamiin.li/api_proxy.php/activity/add', {
        firstName: formData.firstName,
        lastName: formData.lastName,
        telephone: formData.telephone,
        email: formData.email,
        comment: formData.comment,
        subscribe: formData.subscribe,
        type: 'CONTACT',
      })
      .then(() => {
        window.location.replace(`/:lng/contact-confirmation`)
      })
      .catch((error) => {
        console.error('Error submitting the form:', error)
      })
  }

  return (
    <div className="max-w-6xl w-full mt-[60px] mb-[100px] w-full mx-auto justify-center p-[15px] sm:p-0">
      <h1 className="hero-title">{t('contact')}</h1>
      <div className="mt-0 mx-auto max-w-7xl px-0 sm:px-0 lg:px-0">
        <div className="rounded-0 md:rounded-3xl bg-[#efefef] py-10 px-6 sm:py-16 sm:px-12 md:flex md:p-20">
          <div className="w-full max-w-2xl md:mt-0 md:me-0 md:flex-1">
            <form method="post" className="">
              <div className="flex flex-wrap md:pe-5">
                <div className="w-full pb-3 flex flex-wrap md:flex-nowrap">
                  <div className="w-full pb-3 md:pb-0">
                    <input
                      id="firstname"
                      name="firstName"
                      type="text"
                      className={`w-full rounded-md border ${errors.firstName ? 'border-red-500 shadow-error' : 'border-white'} p-[15px] placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-700`}
                      placeholder={t('contactBoxFirstname')}
                      onChange={handleChange}
                      value={formData.firstName}
                    />
                  </div>
                  <div className="w-full">
                    <input
                      id="lastname"
                      name="lastName"
                      type="text"
                      className={`w-full rounded-md border ${errors.lastName ? 'border-red-500 shadow-error' : 'border-white'} p-[15px] placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-700`}
                      placeholder={t('contactBoxLastname')}
                      onChange={handleChange}
                      value={formData.lastName}
                    />
                  </div>
                </div>
                <div className="w-full pb-3">
                  <input
                    id="telephone"
                    name="telephone"
                    type="text"
                    className="w-full rounded-md border-white p-[15px] placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-700"
                    placeholder={t('contactBoxTelephone')}
                    onChange={handleChange}
                    value={formData.telephone}
                  />
                </div>
                <div className="w-full pb-3">
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className={`w-full rounded-md border ${errors.email ? 'border-red-500 shadow-error' : 'border-white'} p-[15px] placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-700`}
                    placeholder={t('contactBoxEmail')}
                    onChange={handleChange}
                    value={formData.email}
                  />
                </div>
                <div className="w-full pb-3">
                  <textarea
                    className={`w-full p-3 rounded-md border ${errors.comment ? 'border-red-500 shadow-error' : 'border-white'}`}
                    rows="5"
                    name="comment"
                    placeholder={t('contactBoxComment')}
                    onChange={handleChange}
                    value={formData.comment}
                  />
                </div>
                <div className="flex flex-wrap">
                  <div className="flex">
                    <div style={{ color: '#555' }}>
                      <input
                        type="checkbox"
                        name="confirm"
                        className={` ${errors.confirm ? 'border-red-500 shadow-error' : ''}`}
                        tabIndex="-1"
                        autoComplete="off"
                        required
                        onChange={handleChange}
                        checked={formData.confirm}
                      />
                    </div>
                    <div
                      id="newsletterPP"
                      className="w-full mb-6 sm:mb-0 ps-2 text-[#555] text-sm"
                    >
                      {t('newsletterBoxConsent')}
                      <Link
                        to={`/:lng/${t('slugPrivacyPolicy')}`}
                        target="_blank"
                        className="text-sm"
                      >
                        {t('privacyPolicy')}
                      </Link>
                      .
                    </div>
                  </div>
                  <div className="flex mt-3">
                    <div style={{ color: '#555' }}>
                      <input
                        type="checkbox"
                        name="subscribe"
                        className=""
                        tabIndex="-1"
                        autoComplete="off"
                        onChange={handleChange}
                        checked={formData.subscribe}
                      />
                    </div>
                    <div
                      id="newsletterOptin"
                      className="mb-6 sm:mb-0 ps-2 text-[#555] text-sm"
                    >
                      {t('newsletterBoxOptin')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-6">
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="button-cta bg-primary ms-0"
                >
                  {t('newsletterBoxCta')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
