import React from 'react'

export default function TextCenter({ id, title, content, icon }) {

  return (
    <>
      <div
        id="module-text-center"
        className="w-full flex items-center max-w-3xl px-3 md:py-0 mx-auto"
      >
        <div className="w-full py-0  mx-auto text-center">
          <div className="mb-[0px] flex flex-wrap md:justify-center md:text-center">
            {icon &&
            <div className='w-full flex justify-center mb-[30px]'>
              <div dangerouslySetInnerHTML={{ __html: icon }}></div>
            </div>
            }
            <div>
              <h2 className="hero-title text-left md:text-center">
                <div dangerouslySetInnerHTML={{ __html: title }}></div>
              </h2>
            </div>
          </div>
          <div className="mt-[20px] text-left md:text-center">
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          </div>
        </div>
      </div>
    </>
  )
}
