import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function GoBack() {
  const navigate = useNavigate()

  return (
    <>
      <div className="w-full">
        <button className="underline text-colo1" onClick={() => navigate(-1)}>
          Back to previous page
        </button>
      </div>
    </>
  )
}
