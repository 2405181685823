import React from 'react'
import { Link } from 'react-router-dom'

const AdminSidebar = () => {
  return (
    <>
      <div className="flex flex-col items-center w-16 h-full overflow-hidden text-gray-400 bg-color6 rounded-[10px]">
        <Link
          className="flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-gray-700 hover:text-gray-300"
          to="/:ln/admin/home"
        >
          <svg
            className="fill-white"
            width="30px"
            height="30px"
            viewBox="0 0 32 32"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M31.772 16.043l-15.012-15.724c-0.189-0.197-0.449-0.307-0.721-0.307s-0.533 0.111-0.722 0.307l-15.089 15.724c-0.383 0.398-0.369 1.031 0.029 1.414 0.399 0.382 1.031 0.371 1.414-0.029l1.344-1.401v14.963c0 0.552 0.448 1 1 1h6.986c0.551 0 0.998-0.445 1-0.997l0.031-9.989h7.969v9.986c0 0.552 0.448 1 1 1h6.983c0.552 0 1-0.448 1-1v-14.968l1.343 1.407c0.197 0.204 0.459 0.308 0.722 0.308 0.249 0 0.499-0.092 0.692-0.279 0.398-0.382 0.411-1.015 0.029-1.413zM26.985 14.213v15.776h-4.983v-9.986c0-0.552-0.448-1-1-1h-9.965c-0.551 0-0.998 0.445-1 0.997l-0.031 9.989h-4.989v-15.777c0-0.082-0.013-0.162-0.032-0.239l11.055-11.52 10.982 11.507c-0.021 0.081-0.036 0.165-0.036 0.252z"></path>
          </svg>
        </Link>
        <div className="flex flex-col items-center mt-3 border-t border-gray-700">
          <Link
            className="flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-gray-700 hover:text-gray-300"
            to="/:ln/admin/products"
          >
            <svg
              className="fill-white"
              width="30px"
              height="30px"
              viewBox="0 0 52 52"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.2,2.2a1.63,1.63,0,0,1,1.7,1.7V5a1.63,1.63,0,0,1-1.7,1.7H10.1A3.4,3.4,0,0,0,6.7,9.9v32a3.4,3.4,0,0,0,3.2,3.4h32a3.4,3.4,0,0,0,3.4-3.2V36.8A1.63,1.63,0,0,1,47,35.1h1.1a1.63,1.63,0,0,1,1.7,1.7V43A6.81,6.81,0,0,1,43,49.8H9A6.81,6.81,0,0,1,2.2,43V9A6.81,6.81,0,0,1,9,2.2Z"
                fillRule="evenodd"
              />
              <path d="M40.4,25.4l3.1-3.1a1,1,0,0,0,0-1.5L31,8.2a1,1,0,0,0-1.5,0l-3.1,3.1a1,1,0,0,0,0,1.5L38.9,25.3A1,1,0,0,0,40.4,25.4Z" />
              <path d="M47.4,18.3l1.5-1.5a1,1,0,0,0,0-1.5L36.5,2.7a1,1,0,0,0-1.5,0L33.5,4.2a1,1,0,0,0,0,1.5L46,18.3A1,1,0,0,0,47.4,18.3Z" />
              <path d="M36.8,28.9h0c.6-.6.5-1.1.1-1.5L24.4,14.9a1,1,0,0,0-1.5,0l-.1.1a1,1,0,0,0,0,1.5L35.3,29A1,1,0,0,0,36.8,28.9Z" />
              <path d="M32.4,33.3l.7-.7a1,1,0,0,0,0-1.5L20.7,18.5a1,1,0,0,0-1.5,0l-.7.7a1,1,0,0,0,0,1.5L31,33.2C31.3,33.8,31.9,33.8,32.4,33.3Z" />
              <path d="M25.7,40.1,28.8,37a1,1,0,0,0,0-1.5L16.3,22.9a1,1,0,0,0-1.5,0L11.7,26a1,1,0,0,0,0,1.5L24.2,40A1,1,0,0,0,25.7,40.1Z" />
            </svg>
          </Link>
        </div>
        <Link
          className="flex items-center justify-center w-16 h-16 mt-auto bg-gray-800 hover:bg-gray-700 hover:text-gray-300"
          to="/:ln/admin/config"
        >
          <svg
            className="w-6 h-6 stroke-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width="30px"
            height="30px"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </Link>
      </div>
    </>
  )
}

export default AdminSidebar
