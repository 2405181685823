import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/fontawesome-free-solid'

export default function HeroSimple({
  title,
  content,
  cta,
  ctaLink,
  ctaAlt,
  ctaAltLink,
}) {

  return (
    <>
      <div className="w-full bg-white">
        <div className="max-w-6xl mx-auto py-16 px-3 sm:px-0 lg:px-0">
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
            <div className="max-w-lg flex flex-wrap">
              <div className="w-full">
                <h1 className="hero-title">{title}</h1>
              </div>
              <div className="mt-[45px]">
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
              </div>
              <div className="w-full flex flex-wrap md:flex-block mt-[30px]">
                {cta && (
                  <div className="ms-0 mt-[30px] md:ms-0 md:mt-0">
                    <Link to={ctaLink}>
                      <button className="primary-btn">
                        {cta}
                        <FontAwesomeIcon
                          className="ms-3"
                          icon={faArrowCircleRight}
                        />
                      </button>
                    </Link>
                  </div>
                )}

                {ctaAlt && (
                  <div className="ms-0 mt-[30px] md:ms-3 md:mt-0">
                    <Link to={ctaAltLink}>
                      <button className="light-btn">{ctaAlt}</button>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
