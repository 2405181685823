import React from 'react'
import { useTranslation } from 'react-i18next'

import BoxInfo from '../../components/modules/box-info'

const UnderConstruction = () => {
  const { t } = useTranslation()
  return (
    <>
      <BoxInfo
        icon={t('icon404black')}
        title={t('underConstructionTitle')}
        content={t('underConstructionContent')}
        content2={t('underConstructionContent2')}
        cta={t('Contact')}
        ctaLink={`/:lng/${t('slugContact')}`}
      />
    </>
  )
}
export default UnderConstruction
