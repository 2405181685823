import React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import BoxInfo from '../../../components/modules/box-info'
import { useLocation } from 'react-router-dom'
import axios from 'axios'

export default function UnsubscribeConfirmation() {
  const { t } = useTranslation()
  const location = useLocation()

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const id = query.get('id')
    axios
      .post('https://benjamiin.li/api_proxy.php/newsletter/unsubscribe', {
        contactId: id,
      })
      .then(() => {
        console.log('Unsubscribed')
      })
  }, [location])
  return (
    <>
      <BoxInfo
        icon={t('iconThankYouBlack')}
        title={t('unsubscribeConfirmationTitle')}
        content={t('unsubscribeConfirmationContent')}
        content2={t('unsubscribeConfirmationContent2')}
        cta={t('newsletter')}
        ctaLink={`/lng:/${t('slugNewsletter')}`}
      />
    </>
  )
}
