import React from 'react'

export default function AdvertHorizontal({ image, name, title, content }) {

  return (
    <>
      <div className="w-full bg-primary  text-white flex items-center justify-center text-uppercase mb-[60px] h-[160px]">
        <h3>Your ad is here</h3>
      </div>
    </>
  )
}
