import React from 'react'
import { Outlet } from 'react-router-dom'

import Footer from '../../components/parts/footer.tsx'
import Subheader from '../../components/parts/subheader.tsx'
import NavigationMenu from '../../components/parts/navigation-menu.tsx'

const TestLayout = () => {
  return (
    <>
      <div className="test-layout">
        <Subheader />
        <NavigationMenu />
        <Outlet />
        <Footer />
      </div>
    </>
  )
}

export default TestLayout
