import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const Dev = () => {
  const { t } = useTranslation()

  const [credentials, setCredentials] = useState({
    title: '',
    titleMaxLength: 10,
    brand: '',
    brandMaxLength: 30,
  })

  const onChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
      [e.target.name.length]: e.target.value.length,
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    console.log(credentials)
  }

  return (
    <>
      <div className="dev mt-[100px]">
        <div className="flex flex-wrap justify-center max-w-7xl w-full mx-auto px-3">
          <div className="w-full text-center">
            <h1>Create your product</h1>
          </div>
          <div className="w-full text-center mt-5">
            <p>
              In order to have your product on our e-shop, you have to fill out
              few forms.
              <br />
              At the moment, you have completed 20% of the procedure
            </p>
          </div>
          <div className="max-w-xl w-full bg-color2 rounded-md mt-5 p-5">
            <div>
              <form onSubmit={onSubmit}>
                <div className="text-center mb-3">
                  <h3>Product information</h3>
                </div>
                <div className="group w-full flex items-center justify-start mb-2">
                  <div>
                    <label htmlFor="title" className="hidden">
                      title
                    </label>
                  </div>
                  <div className="w-full">
                    {' '}
                    <input
                      className="w-full"
                      type="text"
                      name="title"
                      id="title"
                      value={credentials.title}
                      placeholder="Title *"
                      onChange={onChange}
                      maxLength={credentials.titleMaxLength}
                      required
                    />
                  </div>
                  <div className="w-[40px] text-end text-sm me-1">
                    {credentials.title.length}
                  </div>
                  <div className="w-[40px] text-end flex text-sm text-gray-500">
                    / {credentials.titleMaxLength}
                  </div>
                </div>

                <div className="group w-full flex items-center justify-start mb-2">
                  <div>
                    <label htmlFor="brand" className="hidden">
                      brand
                    </label>
                  </div>
                  <div className="w-full">
                    {' '}
                    <input
                      className="w-full"
                      type="text"
                      name="brand"
                      id="brand"
                      value={credentials.brand}
                      placeholder="brand *"
                      onChange={onChange}
                      maxLength={credentials.brandMaxLength}
                      required
                    />
                  </div>
                  <div className="w-[40px] text-end text-sm me-1">
                    {credentials.brand.length}
                  </div>
                  <div className="w-[40px] text-end flex text-sm text-gray-500">
                    / {credentials.brandMaxLength}
                  </div>
                </div>
                <div className="mb-2">
                  <span className="font-bold me-1">Category</span>*
                </div>
                <div className="group w-full flex justify-start mb-3">
                  <button className="w-full button-submit button-cta bg-primary text-white">
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <div className="flex items-center">
              <div className="text-red-500 me-1">(*)</div>
              <div className="text-sm">
                All these fields are mandatory and have to be filled-out.
              </div>
            </div>
          </div>
        </div>
        <br />
        {t('contact')}
      </div>
    </>
  )
}

export default Dev
