import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function ProfileBody({ id, title, content, image, abc }) {
  const { t } = useTranslation()

  /*const img1 =
    'https://images.pexels.com/photos/11823968/pexels-photo-11823968.jpeg'
  const img2 =
    'https://images.pexels.com/photos/1099680/pexels-photo-1099680.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  const img3 =
    'https://images.pexels.com/photos/1640772/pexels-photo-1640772.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  const slides = [img1, img2, img3]*/

  return (
    <>
      <div
        id="TextNegative"
        className="w-full flex flex-wrap max-w-7xl px-3 md:py-0 mx-auto"
      >
        <div className="w-full max-w-6xl mx-auto flex mt-[30px] mb-[15px] text-left md:text-left text-gray-500">
          {/*
          <div>Home&nbsp;</div>
          <div>/ Profiles / ...</div>
          */}
        </div>
        <div className="w-full w-full max-w-6xl mx-auto">
          <div className="mb-[0px] text-left md:text-left">
            <h2 className="hero-title">
              <div dangerouslySetInnerHTML={{ __html: title }}></div>
            </h2>
          </div>
        </div>
      </div>
      <div className="w-full flex max-w-6xl md:py-0 mx-auto mt-[30px]">
        <div className="col-span-4 md:col-span-3">
          <div className="w-full max-w-[95%] py-0 mr-auto text-left">
            <div className="md:w-3/4 mt-[0px] text-left md:text-left p-[15px] md:p-[0px]">
              
              {t('silviaIntro')}
              
              <h3 className='mt-[30px]'>{t('silviaTitel1')}</h3>
              <p>
                {t('silviaContent1')}
              </p>
              <Link to={'https://silviatriebl.ch/topf-sucht-deckel/'}>
                <button className="light-btn">{t('readMore')}</button>
              </Link>
            </div>
          </div>
        </div>

        {/*
        <div className="hidden md:flex flex-wrap">
          <div className="w-full">
            <div className="w-full bg-primary  text-white flex items-center justify-center text-uppercase h-[600px]">
              Your ad is here
            </div>
            <div className="w-full mt-[30px]">...</div>
          </div>
        </div>
        */}

      </div>
    </>
  )
}
