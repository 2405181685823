import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { Link } from 'react-router-dom'

const Login = () => {
  const { t } = useTranslation()

  const [credentials, setCredentials] = useState({
    login: 'login',
    password: 'password',
  })

  const onChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    console.log(credentials)
  }

  return (
    <>
      <div className="login">
        <div className="w-full flex flex-wrap justify-center items-center max-w-7xl w-full mx-auto px-3">
          <div className="max-w-xl w-full bg-color2 rounded-md p-5">
            <div>
              <form onSubmit={onSubmit}>
                <div className="text-center mb-5">
                  <h2>Login form</h2>
                </div>
                <div className="group w-full flex justify-center mb-3">
                  <label className="hidden">Login</label>
                  <input
                    type="text"
                    name="login"
                    id="login"
                    value={credentials.login}
                    onChange={onChange}
                  />
                </div>
                <div className="group w-full flex justify-center mb-3">
                  <label className="hidden">Password</label>
                  <input
                    type="text"
                    name="password"
                    id="password"
                    value={credentials.password}
                    onChange={onChange}
                  />
                </div>
                <div className="group w-full flex justify-center mt-5 mb-[45px]">
                  <button className="button-cta bg-primary text-white">
                    Submit
                  </button>
                </div>
                <div className="mt-5 text-center">
                  <Link to={`/${i18n.language}/auth/register`}>
                    {t('registerHere')}
                  </Link>
                </div>
                <div className="mt-5">
                  Test: localhost:3000/en/admin/products
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
