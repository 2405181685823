import React, { useState } from 'react'
import './style/index.css'

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import PublicRoute from './pages/public/public-route.tsx'
import AdminRoute from './pages/admin/admin-route.tsx'
import TestRoute from './pages/test/test-route.tsx'
import AuthRoute from './pages/auth/auth-route.tsx'
import AuthGuard from './pages/_helpers/auth-guard.tsx'
import { ThemeContextProvider } from './context/theme-context.tsx'

export default function App() {

  return (
    <>
      <div className="App">
        <ThemeContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/*" element={<PublicRoute />} />

              <Route
                path="/:lng/admin/*"
                element={
                  <AuthGuard>
                    <AdminRoute />
                  </AuthGuard>
                }
              />
              <Route path="/:lng/auth/*" element={<AuthRoute />} />
              <Route path="/:lng/test/*" element={<TestRoute />} />
            </Routes>
          </BrowserRouter>
        </ThemeContextProvider>
      </div>
    </>
  )
}
