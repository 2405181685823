import React from 'react'
import { Link } from 'react-router-dom'

const AdminProduct = () => {
  return (
    <>
      <div className="admin-home flex flex-wrap justify-center">
        <div className="w-full text-center">
          <h1>Hello {}</h1>
        </div>
        <div className="w-full flex flex-wrap justify-center mt-[45px]">
          <Link to="/:ln/admin/products">
            <div className="list-box">
              <div className="list-box-icon">
                <svg
                  className="fill-color1"
                  width="80px"
                  height="80px"
                  viewBox="0 0 52 52"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.2,2.2a1.63,1.63,0,0,1,1.7,1.7V5a1.63,1.63,0,0,1-1.7,1.7H10.1A3.4,3.4,0,0,0,6.7,9.9v32a3.4,3.4,0,0,0,3.2,3.4h32a3.4,3.4,0,0,0,3.4-3.2V36.8A1.63,1.63,0,0,1,47,35.1h1.1a1.63,1.63,0,0,1,1.7,1.7V43A6.81,6.81,0,0,1,43,49.8H9A6.81,6.81,0,0,1,2.2,43V9A6.81,6.81,0,0,1,9,2.2Z"
                    fillRule="evenodd"
                  />
                  <path d="M40.4,25.4l3.1-3.1a1,1,0,0,0,0-1.5L31,8.2a1,1,0,0,0-1.5,0l-3.1,3.1a1,1,0,0,0,0,1.5L38.9,25.3A1,1,0,0,0,40.4,25.4Z" />
                  <path d="M47.4,18.3l1.5-1.5a1,1,0,0,0,0-1.5L36.5,2.7a1,1,0,0,0-1.5,0L33.5,4.2a1,1,0,0,0,0,1.5L46,18.3A1,1,0,0,0,47.4,18.3Z" />
                  <path d="M36.8,28.9h0c.6-.6.5-1.1.1-1.5L24.4,14.9a1,1,0,0,0-1.5,0l-.1.1a1,1,0,0,0,0,1.5L35.3,29A1,1,0,0,0,36.8,28.9Z" />
                  <path d="M32.4,33.3l.7-.7a1,1,0,0,0,0-1.5L20.7,18.5a1,1,0,0,0-1.5,0l-.7.7a1,1,0,0,0,0,1.5L31,33.2C31.3,33.8,31.9,33.8,32.4,33.3Z" />
                  <path d="M25.7,40.1,28.8,37a1,1,0,0,0,0-1.5L16.3,22.9a1,1,0,0,0-1.5,0L11.7,26a1,1,0,0,0,0,1.5L24.2,40A1,1,0,0,0,25.7,40.1Z" />
                </svg>
              </div>
              <div className="list-box-subtitle">Your offers</div>
            </div>
          </Link>
          <Link to="/:ln/admin/products">
            <div className="list-box">
              <div className="list-box-icon">
                <svg
                  className="fill-color1"
                  width="80px"
                  height="80px"
                  viewBox="0 0 52 52"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.2,2.2a1.63,1.63,0,0,1,1.7,1.7V5a1.63,1.63,0,0,1-1.7,1.7H10.1A3.4,3.4,0,0,0,6.7,9.9v32a3.4,3.4,0,0,0,3.2,3.4h32a3.4,3.4,0,0,0,3.4-3.2V36.8A1.63,1.63,0,0,1,47,35.1h1.1a1.63,1.63,0,0,1,1.7,1.7V43A6.81,6.81,0,0,1,43,49.8H9A6.81,6.81,0,0,1,2.2,43V9A6.81,6.81,0,0,1,9,2.2Z"
                    fillRule="evenodd"
                  />
                  <path d="M40.4,25.4l3.1-3.1a1,1,0,0,0,0-1.5L31,8.2a1,1,0,0,0-1.5,0l-3.1,3.1a1,1,0,0,0,0,1.5L38.9,25.3A1,1,0,0,0,40.4,25.4Z" />
                  <path d="M47.4,18.3l1.5-1.5a1,1,0,0,0,0-1.5L36.5,2.7a1,1,0,0,0-1.5,0L33.5,4.2a1,1,0,0,0,0,1.5L46,18.3A1,1,0,0,0,47.4,18.3Z" />
                  <path d="M36.8,28.9h0c.6-.6.5-1.1.1-1.5L24.4,14.9a1,1,0,0,0-1.5,0l-.1.1a1,1,0,0,0,0,1.5L35.3,29A1,1,0,0,0,36.8,28.9Z" />
                  <path d="M32.4,33.3l.7-.7a1,1,0,0,0,0-1.5L20.7,18.5a1,1,0,0,0-1.5,0l-.7.7a1,1,0,0,0,0,1.5L31,33.2C31.3,33.8,31.9,33.8,32.4,33.3Z" />
                  <path d="M25.7,40.1,28.8,37a1,1,0,0,0,0-1.5L16.3,22.9a1,1,0,0,0-1.5,0L11.7,26a1,1,0,0,0,0,1.5L24.2,40A1,1,0,0,0,25.7,40.1Z" />
                </svg>
              </div>
              <div className="list-box-subtitle">Your products</div>
            </div>
          </Link>
        </div>
      </div>
    </>
  )
}

export default AdminProduct
