import React from 'react'
import { useTranslation } from 'react-i18next'

import Hero from '../../../components/modules/hero'
import TextCenter from '../../../components/modules/text-center'
import BentoGrid from '../../../components/modules/bento-grid'
import BlockTextImageColorDiagonal from '../../../components/modules/block-text-image-color-diagonal'
import BlockTextImage from '../../../components/modules/block-text-image'

export default function ChampionsClub() {
  const { t } = useTranslation()

  return (
    <>
      <Hero
        title={t('championsHeroTitle')}
        content={t('championsHeroContent')}
        background={
          'https://benjamiin.com/uploads/images/children/benjamiin-champions-club.jpeg'
        }
        cta={undefined}
        ctaLink={undefined}
        ctaAlt={undefined}
        ctaAltLink={undefined}
      />

      <div className="max-w-6xl flex-wrap mx-auto w-full my-[0px] sm:p-4">
        <div className="w-full mb-[50px]">
          <TextCenter
            title={t('championsChildrenTitle')}
            content={t('championsChildrenContent')}
            id={undefined}
            icon={undefined}
          />
        </div>
        <div className="mb-[60px]">
          <BentoGrid
            image1={
              'https://benjamiin.com/uploads/images/drawings/benjamiin-drawings-120520242.jpg'
            }
            image2={
              'https://benjamiin.com/uploads/images/merchandising/Benjamiin-merchandising-12052024.jpeg'
            }
            image3={
              'https://benjamiin.com/uploads/images/merchandising/Benjamiin-merchandising-tasche.jpeg'
            }
            image4={
              'https://benjamiin.com/uploads/images/children/Benjamiin-children-14052024-1.jpg'
            }
            image5={
              'https://benjamiin.com/uploads/images/merchandising/Benjamiin-merchandising-Hexe-Adelheid.jpg'
            }
            title1={undefined}
            title2={undefined}
            title3={undefined}
            title4={undefined}
            title5={undefined}
            link1={undefined}
            link2={undefined}
            link3={undefined}
            link4={undefined}
            link5={undefined}
          />
        </div>
      </div>

      <BlockTextImageColorDiagonal
        id={'BlockTextVideoColorDiagonalBeige'}
        title={t('championsAdultsTitle')}
        content={t('championsAdultsContent')}
        url={
          'https://benjamiin.com/uploads/images/events/Benjamiin-events-14052024-1.jpeg'
        }
        cta={undefined}
        ctaLink={undefined}
        ctaAlt={undefined}
        ctaAltLink={undefined}
        image={undefined} //cta={t('story')}
        //ctaLink={`/${i18n.language}/${t('slugStory')}`}
      />

      <div className="max-w-6xl flex-wrap mx-auto w-full my-[0px] sm:p-4">
        <BlockTextImage
          title={t('championsProcessTitle')}
          content={t('championsProcessContent')}
          // cta={'Silvia Triebl'}
          // ctaLink={`/${i18n.language}/ profile`}
          // ctaAlt={'ctaAlt - Alt Button'}
          // ctaAltLink={`/${i18n.language}/en/profile`}
          image={
            'https://benjamiin.com/uploads/images/team/silvia-triebl/Benjamiin-atelier-120520242.jpg'
          }
          cta={undefined}
          ctaLink={undefined}
          ctaAlt={undefined}
          ctaAltLink={undefined}
        />
      </div>
    </>
  )
}
