import React from 'react'
import { useTranslation } from 'react-i18next'

import BlockTextImage from '../../../components/modules/block-text-image'
import Hero from '../../../components/modules/hero'
import BlockTextVideoColor from '../../../components/modules/block-text-video-color'

export default function Recipes() {
  const { t } = useTranslation()

  return (
    <>
      <Hero
        title={t('recipesHeroTitle')}
        content={t('recipesHeroContent')}
        background={
          'https://benjamiin.com/uploads/images/children/Benjamiin-leandro-14052024.webp'
        }
        cta={undefined}
        ctaLink={undefined}
        ctaAlt={undefined}
        ctaAltLink={undefined}
      />

      <div className="max-w-6xl flex-wrap mx-auto w-full my-[0px] sm:p-4">
        <BlockTextImage
          title={t('recipesForChildrenTitle')}
          content={t('recipesForChildrenContent')}
          // cta={'Silvia Triebl'}
          // ctaLink={`/${i18n.language}/ profile`}
          // ctaAlt={'ctaAlt - Alt Button'}
          // ctaAltLink={`/${i18n.language}/en/profile`}
          image={
            'https://benjamiin.com/uploads/images/children/benjamiin-children-1.jpeg'
          }
          cta={undefined}
          ctaLink={undefined}
          ctaAlt={undefined}
          ctaAltLink={undefined}
        />
      </div>

      <BlockTextVideoColor
        id={'BlockTextVideoColorRegularBeige'}
        title={t('recipeVideosTitle')}
        content={t('recipeVideosContent')}
        // cta={t('partnersHeroCta')}
        // ctaLink={`https://enudehac.myhostpoint.ch/benjamiin-company-strategy.pdf`}
        // ctaAlt={'ctaAlt - Alt Button'}
        // ctaAltLink={`/${i18n.language}/en/profile`}
        url={'https://www.youtube.com/embed/F4rEdtEtnWk'}
        cta={undefined}
        ctaLink={undefined}
        ctaAlt={undefined}
        ctaAltLink={undefined}
        image={undefined}
      />
    </>
  )
}
