import React from 'react'
import { useTranslation } from 'react-i18next'

import TitleCenter from '../../../components/modules/title-center'
import SubscriptionNewsletter from '../../../components/modules/subscription-newsletter'

export default function Newsletter() {
  const { t } = useTranslation()

  return (
    <>
      <div className="max-w-6xl w-full mt-[60px] mb-[100px] w-full mx-auto justify-center sm:p-0">
        <TitleCenter
          title={t('newsletterTitle')}
          content={t('newsletterContent')}
          cta={undefined}
          ctaAlt={undefined}
          ctaLink={undefined}
          ctaAltLink={undefined}
        />

        <SubscriptionNewsletter />
      </div>
    </>
  )
}
