import React from 'react'
import { Routes, Route } from 'react-router-dom'

import TestLayout from '../../pages/test/test-layout.tsx'
import { NotFound, Dev, Test, TestAgain, TestAgain2 } from '../test'

const TestRoute = () => {
  return (
    <>
      <Routes>
        <Route element={<TestLayout />}>
          <Route index element={<Dev />} />
          <Route path={'/dev'} element={<Dev />} />
          <Route path={'/test'} element={<Test />} />
          <Route path={'/test-again'} element={<TestAgain />} />
          <Route path={'/test-again2'} element={<TestAgain2 />} />
          <Route path={`*`} element={<NotFound />} />
        </Route>
      </Routes>
    </>
  )
}

export default TestRoute
