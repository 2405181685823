import React, { createContext, useContext, useState } from "react";

export type Theme = "light" | "dark";

type ThemeContextType = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
};

export const ThemeContextProvider = ({ children }) => {
  const [theme, setTheme] = useState<Theme>("light");

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export const ThemeContext = createContext<ThemeContextType>({
  theme: "light",
  setTheme: () => {}
});


export const useThemeContext = () => {
  const themeContext = useContext(ThemeContext);

  if (themeContext === undefined) {
    throw new Error('useThemeContext must be used within a ThemeContextProvider')
  }

  return themeContext;
}
