import React from 'react'

import i18n from '../../../i18n'
import { useTranslation } from 'react-i18next'
import BoxInfo from '../../../components/modules/box-info'

export default function NotFound() {
  const { t } = useTranslation()

  return (
    <>
      <BoxInfo
        icon={t('icon404black')}
        title={t('pageNotFountTitle')}
        content={t('pageNotFountContent')}
        content2={t('pageNotFountContent2')}
        cta={t('Contact')}
        ctaLink={`/${i18n.language}/${t('slugContact')}`}
      />
    </>
  )
}
