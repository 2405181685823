import React from 'react'
import { Routes, Route } from 'react-router-dom'

import AdminLayout from '../../pages/admin/admin-layout.tsx'
import {
  AdminHome,
  NotFound,
  AdminProducts,
  AdminConfig,
  AdminProductsEdit,
} from '../admin'

const AdminRoute = () => {
  return (
    <>
      <Routes>
        <Route element={<AdminLayout />}>
          <Route index element={<AdminHome />} />
          <Route path={`/home`} element={<AdminHome />} />
          <Route path={`/products`} element={<AdminProducts />} />
          <Route path={`/products/edit/*`} element={<AdminProductsEdit />} />
          <Route path={`/config`} element={<AdminConfig />} />
          <Route path={`*`} element={<NotFound />} />
        </Route>
      </Routes>
    </>
  )
}

export default AdminRoute
