import React from 'react'
import { useTranslation } from 'react-i18next'

import BoxInfo from '../../../components/modules/box-info'

export default function NewsletterSuccess() {
  const { t } = useTranslation()

  return (
    <>
      <BoxInfo
        icon={t('iconThankYouBlack')}
        title={t('newsletterSuccessTitle')}
        content={t('newsletterSuccessContent')}
        content2={t('newsletterSuccessContent2')}
        cta={undefined}
        ctaLink={undefined} // cta={t('Contact')}
        // ctaLink={`/${i18n.language}/${t('slugContact')}`}
      />
    </>
  )
}
