import React, { useReducer } from 'react'

interface State {
  count: number
  error: string | null
  id: string
}

interface Action {
  type: 'increment' | 'decrement'
}

function reducer(state: State, action: Action) {
  const { type } = action

  switch (type) {
    case 'increment': {
      const newCount = state.count + 1
      const hasError = newCount > 10
      return {
        ...state,
        count: hasError ? state.count : newCount,
        error: hasError ? 'max stock reached' : null,
      }
    }
    case 'decrement': {
      const newCount = state.count - 1
      const hasError = newCount < 0
      return {
        ...state,
        count: hasError ? state.count : newCount,
        error: hasError ? ' ' : null,
      }
    }
    default:
      return state
  }
}

export default function TestAgain2() {
  const [state, dispatch] = useReducer(reducer, {
    count: 0,
    error: null,
    id: 'article 1',
  })

  return (
    <>
      <div className="mt-[200px]">
        <div>Count: {state.count}</div>
        <div>Id: {state.id}</div>
        {state.error && <div>{state.error}</div>}
        <button onClick={() => dispatch({ type: 'increment' })}>
          Increment
        </button>
        <button onClick={() => dispatch({ type: 'decrement' })}>
          Decrement
        </button>
      </div>
    </>
  )
}
