import React from 'react'
import { Link } from 'react-router-dom'


export default function CardProfile({
  image,
  name,
  title,
  description,
  cta,
  ctaLink,
  company,
  linkIcon1,
  icon1
}) {

  return (
    <>
      <div className='w-[300px] sm:ms-[15px] sm:me-[15px] mt-[150px]'>
        <div className='relative'>
          <div className='rounded overflow-hidden shadow-md bg-white'>
            <div className='absolute -mt-20 w-full flex justify-center'>
              <div className='h-32 w-32'>
                <img
                  src={image}
                  className='rounded-full object-cover h-full w-full shadow-md'
                  alt='...'
                />
              </div>
            </div>
            <div className='flex flex-wrap px-6 mt-16'>
              <div className='w-full flex justify-center text-center'>
                <h3 className='pb-0 mb-0'>{name}</h3>
              </div>
              <div className='w-full pt-0 text-gray-400 flex justify-center Class	text-sm'>
                {title}
              </div>
              {company &&
              <div className='w-full pt-0 text-gray-400 flex justify-center Class	text-sm'>
                {company}
              </div>
              }
              {description &&
              <div className='w-full flex justify-center pt-[25px] pb-[25px] text-center'>
                {description}
              </div>
              }
              {ctaLink &&
              <div className='w-full flex justify-center pt-[0px] pb-[25px] text-center'>
                <Link to={ctaLink}>
                  <button className='light-btn'>{cta}</button>
                </Link>
              </div>
              }
              <div className='w-full flex justify-center pt-3 pb-5'>
                <Link to={linkIcon1} className='mx-5'>
                  <div aria-label='icon1'>
                    <div dangerouslySetInnerHTML={{ __html: icon1 }}></div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
