import React from 'react'

const AdminProductsEdit = () => {
  return (
    <>
      <div className="admin-home flex flex-wrap justify-center">
        <div className="w-full text-center">
          <h1>Your offers</h1>
        </div>
        <div className="w-full flex flex-wrap justify-center mt-[45px]">
          Here is where we can amend products
        </div>
      </div>
    </>
  )
}

export default AdminProductsEdit
