import React from 'react'
import { Outlet } from 'react-router-dom'

import Footer from '../../components/parts/footer.tsx'
import Subheader from '../../components/parts/subheader.tsx'
import Navbar from '../../components/parts/navbar.tsx'
import { AdminSidebar } from '../admin'

const AdminLayout = () => {
  return (
    <>
      <div className="admin-layout">
        <div className="bg-white min-h-[80vh]">
          <div className="fixed top-[150px] left-[10px]">
            <AdminSidebar />
          </div>
          <div>
            <Subheader />
            <Navbar />
          </div>
          <div className="w-full md:max-w-7xl md:mx-auto px-3 mt-[60px]">
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default AdminLayout
