import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/fontawesome-free-solid'

export default function Hero({
  title,
  content,
  cta,
  ctaLink,
  ctaAlt,
  ctaAltLink,
  background,
}) {

  return (
    <>
      <div
        className="hero w-full bg-white"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="max-w-6xl flex mx-auto w-full grid md:grid-cols-2 md:gap-16 text-gray-800 p-2 md:p-5 sm:p-0">
          <div className="font-semibold"></div>
          {content && (
            <div className="flex flex-wrap px-[30px] py-[45px] bg-white">
              <div className="hero-box">
                <h1 className="mb-[15px] pb-0">{title}</h1>
              </div>
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
              {cta && (
                <div className="w-full flex flex-wrap md:flex-block mt-[30px]">
                  <div className="">
                    <Link to={ctaLink}>
                      <button className="primary-btn">
                        {cta}
                        <FontAwesomeIcon
                          className="ms-3"
                          icon={faArrowCircleRight}
                        />
                      </button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}
