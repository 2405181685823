import React from 'react'
import { Link } from 'react-router-dom'

export default function BentoGrid({
  image1,
  title1,
  image2,
  title2,
  image3,
  title3,
  image4,
  title4,
  image5,
  title5,
  link1,
  link2,
  link3,
  link4,
  link5

}) {
  

  return (
    <>
      <div className="w-full py-4 px-3 mx-auto max-w-screen-xl md:px-0">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4 h-full">
          <div className="col-span-2 sm:col-span-1 md:col-span-2 bg-gray-50 h-auto md:h-full flex flex-col">
          <Link
              to={link1}
              className="group relative flex flex-col overflow-hidden px-4 pb-4 pt-40 flex-grow"
            >
              <img
                src={image1}
                alt=""
                className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
              />
              {title1 &&
                <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5">
                  <h3 className="bento-grid-btn">{title1}</h3>
                </div>
              }
            </Link>
          </div>
          <div className="col-span-2 sm:col-span-1 md:col-span-2 bg-stone-50">
          <Link
              to={link2}
               className="group relative flex flex-col overflow-hidden px-4 pb-4 pt-40 mb-4">
              <img
                src={image2}
                alt=""
                className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
              />
              {title2 &&
                <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5">
                  <h3 className="bento-grid-btn">{title2}</h3>
                </div>
              }
            </Link>
            <div className="grid gap-4 grid-cols-2 sm:grid-cols-2 lg:grid-cols-2">
            <Link
              to={link3}
              className="group relative flex flex-col overflow-hidden px-4 pb-4 pt-40"
              >
                <img
                  src={image3}
                  alt=""
                  className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
                />
              {title3 &&
                <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5">
                  <h3 className="bento-grid-btn">{title3}</h3>
                </div>
              }
              </Link>
              <Link
              to={link4}
              className="group relative flex flex-col overflow-hidden px-4 pb-4 pt-40"
              >
                <img
                  src={image4}
                  alt=""
                  className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
                />
              {title4 &&
                <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5">
                  <h3 className="bento-grid-btn">{title4}</h3>
                </div>
              }
              </Link>
            </div>
          </div>
          <div className="col-span-2 sm:col-span-1 md:col-span-1 bg-sky-50 h-auto md:h-full flex flex-col">
          <Link
              to={link5}
              className="group relative flex flex-col overflow-hidden px-4 pb-4 pt-40 flex-grow"
            >
              <img
                src={image5}
                alt=""
                className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
              />
              {title5 &&
                <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5">
                  <h3 className="bento-grid-btn">{title5}</h3>
                </div>
              }
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
