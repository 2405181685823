import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n'
import { Link } from 'react-router-dom'

export default function Footer() {
  const [locale, setLocale] = useState(i18n.language)
  const { t } = useTranslation()

  i18n.on('languageChanged', (lng) => setLocale(i18n.language))
  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value)
    console.log(locale)
  }

  const languages = [
    {
      code: 'en',
      name: 'English',
      country_code: 'en',
    },
    {
      code: 'de',
      name: 'Deutsch',
      country_code: 'de',
    },
    {
      code: 'fr',
      name: 'Français',
      country_code: 'fr',
    },
  ]

  return (
    <>
      <div className="footer flex flex-wrap w-full mt-[0px]">
        <div className="max-w-7xl mx-auto pt-4">
          <div className="px-3 mx-auto">
            <div className="-mx-4 flex flex-wrap justify-between">
              <div className="px-3 md:px-3 mt-2 w-full xl:w-1/4">
                <div className="w-full">
                  <Link to="/">
                    <img
                      src={
                        'https://enudehac.myhostpoint.ch/assets/logo-benjamiin-regular-white.png'
                      }
                      width={150}
                      alt={'...'}
                    />
                  </Link>
                </div>
                <div className="w-full mt-[30px] md:mt-[30px]">
                  <p className="text-justify">{t('boilerPlate1')}</p>
                </div>
              </div>
              <div>{/* Col 1 */}</div>
              <div className="px-3 md:px-3 md:mt-4 w-full sm:w-auto">
                <div>
                  <h2 className="mt-[30px] md:mt-[0px] inline-block text-2xl border-b-0 border-orange-600">
                    {t('company')}
                  </h2>
                </div>
                <ul className="leading-8 p-0">
                  <li key="slugAboutUs">
                    <Link
                      to={`/${i18n.language}/${t('slugAboutUs')}`}
                      className=""
                    >
                      {t('aboutUs')}
                    </Link>
                  </li>
                  <li key="slugTeam">
                    <Link to={`/${i18n.language}/profile`} className="">
                      Silvia Triebl
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/${i18n.language}/${t('slugTeam')}`}
                      className=""
                    >
                      {t('team')}
                    </Link>
                  </li>
                  <li key="slugPartners">
                    <Link
                      to={`/${i18n.language}/${t('slugPartners')}`}
                      className=""
                    >
                      {t('partners')}
                    </Link>
                  </li>
                  {/*
            <li>
              <Link
                to={`/${i18n.language}/${t('slugSponsoring')}`}
                className=""
              >
                {t('sponsoring')}
              </Link>
            </li>
            */}
                  <li key="slugMedia">
                    <Link
                      to={`/${i18n.language}/${t('slugMedia')}`}
                      className=""
                    >
                      {t('media')}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="px-3 md:px-3 md:mt-4 w-full sm:w-auto">
                <div className="mt-[30px] md:mt-[0px]">
                  <h2 className="inline-block text-2xl border-b-0 border-orange-600">
                    {t('importantLinks')}
                  </h2>
                </div>
                <ul className="leading-8 p-0">
                  <li key="champions">
                    <Link to={`/${i18n.language}/champions-club`} className="">
                      Champions Club
                    </Link>
                  </li>
                  {/*
            <li>
              <Link to={`/${i18n.language}/shop`} className="">
                Shop
              </Link>
            </li>
            <li>
              <Link
                to={`/${i18n.language}/recipes`}
                className=""
              >
                Recipes
              </Link>
            </li>
            <li>
              <Link to={`/${i18n.language}/faq`} className="">
                FAQ
              </Link>
            </li>
          */}
                </ul>
              </div>
              <div className="px-3 md:px- md:mt-4 w-full sm:w-auto mb-[60px] md:mb-[0px]">
                <div className="mt-[30px] md:mt-[0px]">
                  <h2 className="inline-block text-2xl border-b-0 border-orange-600">
                    {t('contact')}
                  </h2>
                </div>
                <div className="flex items-center">
                  <div className="me-1">
                    <Link
                      className=""
                      to="https://www.instagram.com/benjamiin4Kids/"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="white"
                        viewBox="0 0 50 50"
                        width="30px"
                        height="30px"
                      >
                        <path d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z" />
                      </svg>
                    </Link>
                  </div>
                  <div className="me-1">
                    <Link
                      className=""
                      to="https://www.youtube.com/@Benjamiin4kids"
                    >
                      <svg
                        width="50px"
                        height="50px"
                        viewBox="0 0 64 64"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        stroke="#fff"
                      >
                        <path d="M55.16 20.42A5.94 5.94 0 0 0 50 15.6c-6-.38-12-.69-18-.7s-12 .3-18 .68a5.94 5.94 0 0 0-5.17 4.82 79.25 79.25 0 0 0 0 22.88 6.17 6.17 0 0 0 5.19 5c6 .38 12 .77 18 .77s12-.34 18-.73a6.24 6.24 0 0 0 5.18-5.1 78.19 78.19 0 0 0 .8-11.38 78 78 0 0 0-.84-11.42z" />
                        <polygon points="26.56 38.73 26.56 24.79 39.55 31.76 26.56 38.73" />
                      </svg>
                    </Link>
                  </div>
                  <div className="ms-1">
                    <Link
                      className=""
                      to="https://www.linkedin.com/company/benjamiin4kids/"
                    >
                      <svg
                        version="1.1"
                        width="30px"
                        height="30px"
                        viewBox="0 0 32 32"
                        enableBackground="new 0 0 32 32"
                      >
                        <g>
                          <path
                            fill="#fff"
                            d="M19.515,11.952c-0.678,0-1.18,0.171-1.573,0.387c-0.063-0.224-0.27-0.388-0.514-0.339h-2.984
c-0.295-0.049-0.534,0.19-0.443,0.5v11c-0.091,0.28,0.148,0.52,0.443,0.5h2.984c0.295,0.02,0.534-0.22,0.534-0.515l-0.001-6.648
c-0.001-0.003-0.012-0.321,0.189-0.54c0.161-0.174,0.438-0.263,0.821-0.263c0.638,0,0.922,0.261,1.028,0.845v6.606
C19.894,23.78,20.133,24.02,20.428,24h3.145c0.295,0.02,0.534-0.22,0.428-0.515v-6.828C24.106,13.185,21.633,11.952,19.515,11.952z
M23.038,23h-2.076v-6.121c0-1.181-0.763-1.913-1.99-1.913c-0.694,0-1.234,0.204-1.606,0.606C16.849,16.132,16.889,16.842,17,17v6
h-2.022v-9.98h1.916v0.336c0,0.203,0.115,0.39,0.298,0.479c0.183,0.089,0.4,0.067,0.562-0.056l0.14-0.108
c0.405-0.319,0.824-0.65,1.622-0.65c0.826,0,3.523,0.263,3.523,3.637V23z"
                          />
                          <path
                            fill="#fff"
                            d="M11,6.966C9.878,6.966,8.966,7.878,8.966,9S9.878,11.034,11,11.034S13.034,10.122,13.034,9
S12.122,6.966,11,6.966z M11,9.966c-0.532,0-0.966-0.434-0.966-0.966S10.468,8.034,11,8.034S11.966,8.468,11.966,9
S11.532,9.966,11,9.966z"
                          />
                          <path
                            fill="#fff"
                            d="M12.428,11.951H9.46c-0.295,0-0.534,0.239-0.46,0.549v11c-0.074,0.28,0.165,0.52,0.46,0.5h2.968
c0.295,0.02,0.534-0.22,0.534-0.515v-11C12.962,12.19,12.723,11.951,12.428,11.951z M12,23H9.994v-9.98H12V23z"
                          />
                          <path
                            fill="#fff"
                            d="M16-0.034C7.158-0.034-0.034,7.158-0.034,16S7.158,32.034,16,32.034S32.034,24.842,32.034,16
S24.842-0.034,16-0.034z M16,30.966C7.748,30.966,1.034,24.252,1.034,16S7.748,1.034,16,1.034S30.966,7.748,30.966,16
S24.252,30.966,16,30.966z"
                          />
                        </g>
                      </svg>
                    </Link>
                  </div>
                </div>
                <div className="mt-[20px] ">
                  {/* <Link
              to={`/${i18n.language}/${t('slugContact')}`}
              className=""
            >
              Contact form
            </Link>
          </div>
          <div className="mt-[5px] ">
          */}
                  <Link
                    to={`/${i18n.language}/${t('slugNewsletter')}`}
                    className=""
                  >
                    Newsletter
                  </Link>
                </div>
                <div className="mt-[5px] ">
                  <Link to={`tel:+41432437363`} className="">
                    +41 (0)43 243 73 63
                  </Link>
                </div>
                <div className="mt-[5px] ">
                  <Link to={`mailto:info@benjamiin.com`} className="">
                    info@benjamiin.com
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-7xl w-full flex flex-wrap mx-auto md:py-4">
          <div className="w-full flex flex-wrap justify-start md:justify-between mx-auto px-3 md:px-3">
            <div className="flex flex-wrap items-center px-0 md:px-0 w-full text-left sm:w-auto md:text-left">
              <div className="w-full md:w-auto md:ms-[0px]">
                <Link
                  to={`/${i18n.language}/${t('slugImpressum')}`}
                  className=""
                >
                  <button className="">{t('impressum')}</button>
                </Link>
              </div>
              <div className="w-full md:w-auto md:ms-[30px]">
                <Link to={`/${i18n.language}/${t('slugTnc')}`} className="">
                  <button className="">{t('tnc')}</button>
                </Link>
              </div>
              <div className="w-full md:w-auto md:ms-[30px]">
                <Link
                  to={`/${i18n.language}/${t('slugPrivacyPolicy')}`}
                  className=""
                >
                  <button className="">{t('privacyPolicy')}</button>
                </Link>
              </div>
              <div className="w-full mb-[45px] md:mb-[0px] md:w-auto md:ms-[30px]">
                <Link
                  to={`/${i18n.language}/${t('slugCookiePolicy')}`}
                  className=""
                >
                  <button className="">{t('cookiePolicy')}</button>
                </Link>
              </div>
            </div>
            <div className="languageChange">
              <select value={i18n.language} onChange={handleChange}>
                {languages.map(({ code, name, country_code }) => (
                  <option value={country_code} key={name}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="max-w-7xl w-full flex flex-wrap mx-auto py-[30px] md:py-[30px]">
          <div className="px-3 md:px-3">
            <div className="flex flex-wrap justify-between">
              <div className="px-0 md:px-0 py-0 md:py-0  md:mb-0 w-full text-left sm:w-auto sm:text-left ">
                Copyright © 2024, Benjamiin 4 Kids GmbH
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
