import React from 'react'
import { useTranslation } from 'react-i18next'

import BoxInfo from '../../components/modules/box-info'

const Error = () => {
  const { t } = useTranslation()
  return (
    <>
      <BoxInfo
        icon={t('icon404black')}
        title={t('pageNotFountTitle')}
        content={t('pageNotFountContent')}
        content2={t('pageNotFountContent2')}
        cta={t('contact')}
        ctaLink={`/:lng/${t('slugContact')}`}
      />
    </>
  )
}
export default Error
