/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Hero from '../../../components/modules/hero'
import TextCenter from '../../../components/modules/text-center'

export default function Story() {
  const { t } = useTranslation()

  return (
    <>
      <Hero
        title={t('storyHeroTitle')}
        content={t('storyHeroContent')}
        background={
          'https://benjamiin.com/uploads/images/drawings/Benjamiin-family-15052024.jpeg'
        }
        cta={undefined}
        ctaLink={undefined}
        ctaAlt={undefined}
        ctaAltLink={undefined}
      />
      <div className="max-w-6xl flex-wrap mx-auto w-full my-[0px] sm:p-4">
        <div className="w-full mb-[50px]">
          <TextCenter
            title={t('listBooksTitle')}
            content={t('listBooksContent')}
            id={undefined}
            icon={undefined}
          />
        </div>

        <div
          id="bookCard"
          className="flex flex-wrap justify-content-center mb-[80px]"
        >
          <div className="w-[300px] mb-[30px] sm:m-[15px] overflow-hidden bg-white rounded-lg shadow-md">
            <div className="relative">
              <img
                className="w-full h-64 object-cover"
                src="https://benjamiin.com/uploads/images/merchandising/Benjamiin-merchandising-book1.jpeg"
                alt="Image"
              />
              <div className="absolute top-0 right-0">
                <div className="w-32 h-8 absolute top-4 -right-8">
                  <div className="h-full w-full bg-[#decb7a] text-white text-sm text-center leading-8 font-semibold transform rotate-45">
                    {t('book')} #1
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4 flex flex-wrap">
              <div className="w-full">
                <h3 className="text-xl font-semibold mb-2">
                  {t('book1Title')}
                </h3>
              </div>
              <div className="w-full">
                <p className="text-gray-700 text-base">{t('book1Content')}</p>
              </div>
              <div className="w-full text-sm text-[#CCC]">
                Written by{' '}
                <Link className="text-sm" to={`/lng:/profile`}>
                  Silvia Triebl
                </Link>
              </div>
              <div className="w-full text-sm text-[#CCC]">
                Recipes with{' '}
                <Link className="text-sm" to={`/lng:/profile`}>
                  Silvia Triebl
                </Link>
              </div>
            </div>
          </div>

          <div className="w-[300px] mb-[30px] sm:m-[15px] overflow-hidden bg-white rounded-lg shadow-md">
            <div className="relative">
              <img
                className="w-full h-64 object-cover"
                src={
                  'https://benjamiin.com/uploads/images/logos/logo-benjamiin-icon-green.jpg'
                }
                alt={'logo Benjamiin'}
              />
              <div className="absolute top-0 right-0">
                <div className="w-32 h-8 absolute top-4 -right-8">
                  <div className="h-full w-full bg-[#decb7a] text-white text-sm text-center leading-8 font-semibold transform rotate-45">
                    End 2024
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4">
              <h3 className="text-xl font-semibold mb-2">{t('book2Title')}</h3>
              <p className="text-gray-700 text-base">{t('book2Content')}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
