import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import CardProfile from '../../../components/modules/card-profile'
import ReturnParameters from '../../../functions/return-parameters'
import Hero from '../../../components/modules/hero'
import BentoGrid from '../../../components/modules/bento-grid'

export default function Media() {
  ReturnParameters()
  const { t } = useTranslation()

  return (
    <>
      <Hero
        title={t('mediaHeroTitle')}
        content={t('mediaHeroContent')}
        //cta={t('mediaHeroCta')}
        //ctaLink={t('mediaHeroCtaLink')}
        //ctaAlt={'ctaAlt - Alt Button'}
        //ctaAltLink={`/${i18n.language}/en/profile`}
        background={
          'https://benjamiin.com/uploads/images/merchandising/Benjamiin-merchandising-overview.jpeg'
        }
        cta={undefined}
        ctaLink={undefined}
        ctaAlt={undefined}
        ctaAltLink={undefined}
      />
      <div className="max-w-6xl flex-wrap mx-auto w-full mt-[60px] md:mt-[00px] p-[15px] md:p-3">
        <div className="flex flex-wrap justify-content-center align-items-center mb-[50px]">
          <div className="w-full md:w-1/3 sm:me-[100px]">
            <h2 className="hero-title">{t('whoWeAre')} </h2>
            <p className="mt-[30px]">{t('boilerPlate1')}</p>
            <p className="mt-[0px]">{t('boilerPlate2')}</p>
          </div>
          <div>
            <CardProfile
              image={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRG_oM2JRKjnKfsJTW04ud6AziSfy-FS_2hK-q0o8LOdw&s`}
              name={`Silvia Triebl`}
              title={`Founder & CEO`}
              company={`Benjamiin 4 Kids GmbH`}
              icon1={t('iconLinkedInBlack')}
              linkIcon1={
                'https://www.linkedin.com/in/silviatriebl/?originalSubdomain=ch'
              }
              description={undefined}
              cta={undefined}
              ctaLink={undefined} //description={`Texte généré Lorem ipsum dolor sit amet. Ad fuga accusantium est adipisci beatae est harum corrupti sed dolores numquam? Ad cupiditate quis ut mollitia laboriosam qui totam cupiditate.`}
              //cta={`Read more`}
              //ctaLink={`/${i18n.language}/${t('profile')}/silvia-triebl-1000000`}
            />
          </div>
        </div>

        <div className="mb-[60px]">
          <BentoGrid
            image1={
              'https://benjamiin.com/uploads/images/media/media-silvia-triebl-1.jpeg'
            }
            image2={
              'https://benjamiin.com/uploads/images/media/media-silvia-triebl-2.jpg'
            }
            image3={
              'https://benjamiin.com/uploads/images/media/media-silvia-triebl-3.jpeg'
            }
            image4={
              'https://benjamiin.com/uploads/images/media/media-silvia-triebl-4.jpeg'
            }
            image5={
              'https://benjamiin.com/uploads/images/media/media-silvia-triebl-5.jpeg'
            }
            title1={undefined}
            title2={undefined}
            title3={undefined}
            title4={undefined}
            title5={undefined}
            link1={undefined}
            link2={undefined}
            link3={undefined}
            link4={undefined}
            link5={undefined}
          />
        </div>
        <div
          className="mb-[60px] mt-[0px] mx-[15px] md:mx-[0px] p-5"
          style={{ border: '1px solid #555555' }}
        >
          <h2 className="hero-title">Logos</h2>

          <div className="flex mt-[30px]">
            <div>
              <Link to="https://enudehac.myhostpoint.ch/assets/logo-benjamiin-regular-green.png">
                <svg
                  width="25px"
                  height="25px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 3V16M12 16L16 11.625M12 16L8 11.625"
                    stroke="#1C274C"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15 21H9C6.17157 21 4.75736 21 3.87868 20.1213C3 19.2426 3 17.8284 3 15M21 15C21 17.8284 21 19.2426 20.1213 20.1213C19.8215 20.4211 19.4594 20.6186 19 20.7487"
                    stroke="#1C274C"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
            </div>
            <div className="ms-[15px]">Logo Regular, color: green</div>
          </div>

          <div className="flex mt-[15px]">
            <div>
              <Link to="https://enudehac.myhostpoint.ch/assets/logo-benjamiin-regular-white.png">
                <svg
                  width="25px"
                  height="25px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 3V16M12 16L16 11.625M12 16L8 11.625"
                    stroke="#1C274C"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15 21H9C6.17157 21 4.75736 21 3.87868 20.1213C3 19.2426 3 17.8284 3 15M21 15C21 17.8284 21 19.2426 20.1213 20.1213C19.8215 20.4211 19.4594 20.6186 19 20.7487"
                    stroke="#1C274C"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
            </div>
            <div className="ms-[15px]">Logo Regular, color: white</div>
          </div>

          <div className="flex mt-[15px]">
            <div>
              <Link to="https://enudehac.myhostpoint.ch/assets/logo-benjamiin-icon-green.png">
                <svg
                  width="25px"
                  height="25px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 3V16M12 16L16 11.625M12 16L8 11.625"
                    stroke="#1C274C"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15 21H9C6.17157 21 4.75736 21 3.87868 20.1213C3 19.2426 3 17.8284 3 15M21 15C21 17.8284 21 19.2426 20.1213 20.1213C19.8215 20.4211 19.4594 20.6186 19 20.7487"
                    stroke="#1C274C"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
            </div>
            <div className="ms-[15px]">Logo Icon, color: green</div>
          </div>

          <div className="flex mt-[15px]">
            <div>
              <Link to="https://enudehac.myhostpoint.ch/assets/logo-benjamiin-icon-white.png">
                <svg
                  width="25px"
                  height="25px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 3V16M12 16L16 11.625M12 16L8 11.625"
                    stroke="#1C274C"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15 21H9C6.17157 21 4.75736 21 3.87868 20.1213C3 19.2426 3 17.8284 3 15M21 15C21 17.8284 21 19.2426 20.1213 20.1213C19.8215 20.4211 19.4594 20.6186 19 20.7487"
                    stroke="#1C274C"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
            </div>
            <div className="ms-[15px]">Logo Icon, color: white</div>
          </div>
        </div>
      </div>
    </>
  )
}
