import React from 'react'

export default function PrivacyPolicy() {
  return (
    <>
      <div className="max-w-6xl w-full mt-[60px] mb-[100px] w-full mx-auto justify-center p-[15px] sm:p-0">
        <h1 className="hero-title">Privacy Policy</h1>
        <p>
          This Privacy Policy describes how Benjamiin 4 Kids GmbH ("we", "us",
          or "our") collects, uses, and shares personal information when you use
          our website https://benjamiin.com (the "Site"). By accessing or using
          the Site, you agree to the terms of this Privacy Policy.
        </p>

        <h3>Information We Collect</h3>

        <p>
          <b>Personal Information: </b>When you visit the Site, we may collect
          certain personal information such as your name, email address, postal
          address, phone number, and any other information you provide to us
          voluntarily. Usage Data: We may also collect information about how you
          access and use the Site. This may include your IP address, browser
          type, operating system, referring URLs, and other technical
          information. Cookies: We use cookies and similar tracking technologies
          to track your activity on the Site and collect certain information.
          You can disable cookies through your browser settings, but please note
          that certain features of the Site may not function properly if you do
          so.
        </p>

        <h3>How We Use Your Information</h3>
        <p>
          - We may use the information we collect for various purposes,
          including:
          <br />- Providing, maintaining, and improving the Site;
          <br />- Communicating with you and responding to your inquiries;
          <br />- Customizing your experience on the Site;
          <br />- Sending you marketing and promotional communications, where
          permitted by law; and
          <br />- Protecting our rights and interests and those of our users.
          <br />- Sharing Your Information
          <br />
          <br />
          We may share your personal information with third parties who provide
          services on our behalf, such as hosting providers, payment processors,
          and marketing service providers.
          <br />
          We may also share your information in response to legal requests or
          proceedings, to protect our rights or property, or to enforce our
          policies.
        </p>

        <h3>Your Choices</h3>
        <p>
          You can opt out of receiving marketing communications from us by
          following the instructions provided in those communications or by
          contacting us directly.
          <br />
          You can also disable cookies through your browser settings.
        </p>
        <h3>Data Security</h3>
        <p>
          We take reasonable measures to protect your personal information from
          unauthorized access, use, or disclosure. However, no method of
          transmission over the internet or electronic storage is 100% secure,
          so we cannot guarantee absolute security.
        </p>
        <h3>Changes to This Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or applicable laws. We will notify you of any
          material changes by posting the updated Privacy Policy on the Site.
        </p>
        <h3>Contact Us</h3>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          privacy practices, please contact us at info@benjamiin.com.
        </p>
      </div>
    </>
  )
}
