import React from 'react'

import Hero from '../../../components/modules/hero'
import TitleAndCards from '../../../components/modules/title-and-cards'
import AdvertHorizontal from '../../../components/modules/advert-horizontal'

export default function Sponsoring() {
  return (
    <>
      <Hero
        title={`Sponsoring - Lorem ipsum dolor sit amet.`}
        content={`content - Lorem ipsum dolor sit amet. Ut error voluptatibus quo voluptatibus voluptates aut nostrum porro et quisquam voluptatem ad odio voluptatem quo pariatur dolor. Non molestiae ipsum ut soluta aliquam aut galisum beatae.
              <br/><br/>
              Rem voluptatibus dolorem vel voluptas corrupti sit aliquid consequatur ad nesciunt dignissimos vel nihil impedit ut nisi omnis. Aut voluptatibus nemo aut dolor facilis sed laborum commodi.
              `}
        cta={'cta - Primary button'}
        ctaLink={`/ln:/profile`}
        ctaAlt={'ctaAlt - Alt Button'}
        ctaAltLink={`/ln:/profile`}
        background={
          'https://images.pexels.com/photos/11823968/pexels-photo-11823968.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
        }
      />
      <div className="max-w-6xl flex-wrap mx-auto w-full my-[0px] sm:p-4">
        <TitleAndCards title={undefined} content={undefined} />

        <AdvertHorizontal
          image={undefined}
          name={undefined}
          title={undefined}
          content={undefined}
        />
      </div>
    </>
  )
}
