import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/fontawesome-free-solid'

export default function BoxInfo({ icon, title, content, content2, cta, ctaLink }) {

  return (
    <>
      <div className="w-full h-[80vh] flex justify-center mb-[100px]">
        <div className="max-w-7xl w-full mx-auto">
          <div className="w-[320px] mt-[5vh] mx-auto justify-center align-items-center flex-wrap text-center bg-white bg-opacity-90 p-8 sm:rounded-lg sm:shadow-lg">
            <div className="mx-auto flex justify-center mb-[30px]">
              <div dangerouslySetInnerHTML={{ __html: icon }}></div>
            </div>
            <div className="w-full">
              <h1 className="hero-title font-bold text-gray-800 mb-6" style={{fontSize: "2rem"}}>{title}</h1>
            </div>
            <div className="w-full">{content}</div>
            {content2 &&
            <div className="w-full mt-[15px]">{content2}</div>
            }
            {cta &&
            <div className="w-full mt-[30px]">
              <Link to={ctaLink}>
                <button className="button-cta bg-primary mx-auto text-white">
                  {cta}
                  <FontAwesomeIcon className="ms-3" icon={faArrowCircleRight} />
                </button>
              </Link>
            </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}
