import React from 'react'
import { Outlet } from 'react-router-dom'

import Footer from '../../components/parts/footer.tsx'
import Subheader from '../../components/parts/subheader.tsx'
import Navbar from '../../components/parts/navbar.tsx'

const PublicLayout = () => {
  return (
    <>
      <div className="public-layout">
        <Subheader />
        <Navbar />
        <Outlet />
        <Footer />
      </div>
    </>
  )
}

export default PublicLayout
