import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/fontawesome-free-solid'

export default function BlockTextImageColorDiagonal({
  id,
  title,
  content,
  cta,
  ctaLink,
  ctaAlt,
  ctaAltLink,
  image,
  url,
}) {

  return (
    <>
      <div id={id} className="">
        <div className="w-full">
          <div className="max-w-6xl mx-auto py-16 px-3 md:px-3">
            <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
              <div className="max-w-lg flex flex-wrap">
                <div>
                  <h2 className="hero-title pt-[15px] md:pt-[0px]">{title}</h2>
                </div>
                <div className="mt-[30px]">
                  <div dangerouslySetInnerHTML={{ __html: content }}></div>
                </div>
                <div className="w-full flex flex-wrap md:flex-block mb-[30px] md:mb-[0px] mt-[15px]">
                  {cta && (
                    <div className="">
                      <Link to={ctaLink}>
                        <button className="primary-btn">
                          {cta}
                          <FontAwesomeIcon
                            className="ms-3"
                            icon={faArrowCircleRight}
                          />
                        </button>
                      </Link>
                    </div>
                  )}
                  {ctaAltLink && (
                    <div className="ms-0 mt-[15px] md:ms-3 md:mt-0">
                      <Link to={ctaAltLink}>
                        <button className="negative-btn">{ctaAlt}</button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="">
                <div className="hidden md:flex h-[75vh] w-full aspect-w-16 aspect-h-9 items-center justify-content-center">
                  <img
                    src={url}
                    className="w-2/3 h-2/3 rounded-full"
                    alt={'...'}
                  />
                 </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
