import React from 'react'
import { Link } from 'react-router-dom'
import i18n from '../../i18n'

const SimpleLogo = () => {
  return (
    <>
      <div className="w-full flex justify-center pb-[45px]">
        <Link to={`/${i18n.language}/home`}>
          <img
            src={
              'https://enudehac.myhostpoint.ch/assets/logo-benjamiin-regular-green.png'
            }
            width={150}
            alt="..."
          />
        </Link>
      </div>
    </>
  )
}

export default SimpleLogo
