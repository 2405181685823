import React from 'react'
import { Outlet } from 'react-router-dom'
import { SimpleLogo, Footer, GoBack } from '../auth/index.js'

const AuthLayout = () => {
  return (
    <>
      <div className="auth-layout">
        <div className="w-full max-w-7xl flex flex-wrap justify-center items-center mx-auto">
          <div className="hidden md:block max-w-7xl w-full flex justify-center pt-[100px]">
            <GoBack />
          </div>
          <div className="pt-[10vh] md:pt-[15vh] pb-[20vh]">
            <SimpleLogo />
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default AuthLayout
