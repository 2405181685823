import React from 'react'
import { Routes, Route } from 'react-router-dom'

import AuthLayout from '../../pages/auth/auth-layout.tsx'
import { Login, Error, Register } from '../auth/index.js'

const AuthRoute = () => {
  return (
    <>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route index element={<Login />} />
          <Route path={'/login'} element={<Login />} />
          <Route path={'/register'} element={<Register />} />
          <Route path={`*`} element={<Error />} />
        </Route>
      </Routes>
    </>
  )
}

export default AuthRoute
