import React, { useEffect, useState } from 'react'

const TestAgain = () => {
  const [count, setCount] = useState(5)

  function handleClick() {
    setCount(count + 1)
  }

  useEffect(() => {
    if (count === 10) {
      console.log('you reached 10')
    }
  }, [count])

  return (
    <>
      <div className="mt-[200px]">
        {/* {appContextDetails.session.id} */}
        <button onClick={handleClick}>You pressed me {count} times</button>
      </div>
    </>
  )
}

export default TestAgain
