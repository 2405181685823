import React from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '../../../../node_modules/@hookform/resolvers/zod'

const schema = z.object({
  username: z.string().email(),
  password: z.string().min(8),
})

type FormFields = z.infer<typeof schema>

const Test = () => {
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormFields>({
    /* defaultValues: {
      username: '',
    }, */
    resolver: zodResolver(schema),
  })

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      /* console.log(data) */
      /* Check if the email is already taken */
      throw new Error()
    } catch (error) {
      setError('username', {
        message: 'This email address is already registered',
      })
    }
  }

  return (
    <>
      <div className="dev" onSubmit={handleSubmit(onSubmit)}>
        <div className="min-h-[80vh] flex flex-wrap justify-center items-center max-w-7xl w-full mx-auto px-3">
          <div className="max-w-xl w-full bg-color2 rounded-md mt-5 p-5">
            <div>
              <form>
                <div className="text-center my-5">
                  <h3>Register here</h3>
                </div>
                <div className="group w-full flex items-center justify-start mb-2">
                  <div>
                    <label htmlFor="username" className="hidden">
                      username
                    </label>
                  </div>
                  <div className="w-full">
                    <input
                      {...register('username')}
                      className="w-full"
                      type="text"
                      placeholder="Email (*)"
                      required
                    />
                    {errors.username && (
                      <div className="text-red-500">
                        {errors.username.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="group w-full flex items-center justify-start mb-2">
                  <div>
                    <label htmlFor="password" className="hidden">
                      password
                    </label>
                  </div>
                  <div className="w-full">
                    <input
                      {...register('password')}
                      className="w-full"
                      type="password"
                      placeholder="Password (*)"
                    />
                    {errors.password && (
                      <div className="text-red-500">
                        {errors.password.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="group w-full flex justify-start mt-3 mb-3">
                  <button
                    disabled={isSubmitting}
                    className="w-full button-submit button-cta bg-primary text-white"
                  >
                    {isSubmitting ? 'Loading...' : 'Submit'}
                  </button>
                </div>
              </form>
            </div>
            <div className="flex items-center my-5">
              <div className="text-red-500 me-1">(*)</div>
              <div className="text-sm">{t('mandatoryFields')}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Test
