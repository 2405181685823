/* overture port TCP 3336 en entrée */
import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/fontawesome-free-solid'

export default function BlockTextImage({
  title,
  content,
  cta,
  ctaLink,
  ctaAlt,
  ctaAltLink,
  image,
}) {


  return (
    <>
      <div className="flex flex-col md:flex-row items-center max-w-6xl px-3 md:p-0 mx-auto mb-[80px] mt-[80px]">
        <div className="w-full md:w-1/2 py-0">
          <div>
            <h2 className="hero-title">
              <div dangerouslySetInnerHTML={{ __html: title }}></div>
            </h2>
          </div>
          <div className="mt-[30px]">
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          </div>
          {cta && (
            <div className="w-full flex flex-wrap md:flex-block mt-[30px]">
              <div className="">
                <Link to={ctaLink}>
                  <button className="primary-btn">
                    {cta}
                    <FontAwesomeIcon
                      className="ms-3"
                      icon={faArrowCircleRight}
                    />
                  </button>
                </Link>
              </div>
              {ctaAlt && (
                <div className="ms-0 mt-[15px] md:ms-3 md:mt-0">
                  <Link to={ctaAltLink}>
                    <button className="light-btn">{ctaAlt}</button>
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="w-full hidden md:w-1/2 text-center mx-auto mt-[45px] md:mt-0 md:flex justify-center">
          <img src={image} className="g-image" width={'400px'} alt={'...'} />
        </div>
      </div>
    </>
  )
}
