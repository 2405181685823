import React from 'react'

const AdminConfig = () => {
  return (
    <>
      <div className="admin-config">Admin config</div>
    </>
  )
}

export default AdminConfig
