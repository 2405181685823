import React from 'react'

export default function CookiePolicy() {
  return (
    <>
      <div className="max-w-6xl w-full mt-[60px] mb-[100px] w-full mx-auto justify-center p-[15px] sm:p-0">
        <h1 className="hero-title">Cookie Policy</h1>
        <p>
          This Cookie Policy explains how Benjamiin 4 Kids GmbH ("we", "us", or
          "our") uses cookies and similar tracking technologies when you visit
          our website https://benjamiin.com (the "Site").
        </p>
        <h3>What are Cookies?</h3>
        <p>
          Cookies are small text files that are stored on your device (computer,
          tablet, or mobile phone) when you visit a website. They are widely
          used to make websites work more efficiently and to provide information
          to website owners.
        </p>
        <h3>How We Use Cookies</h3>
        <p>
          - <b>Essential Cookies: </b>These cookies are necessary for the
          operation of the Site. They enable basic functions like page
          navigation and access to secure areas of the website. The Site cannot
          function properly without these cookies.
          <br />- <b>Analytical/Performance Cookies:</b> These cookies allow us
          to recognize and count the number of visitors to the Site and to see
          how visitors move around the Site when they are using it. This helps
          us improve the way the Site works, for example, by ensuring that users
          find what they are looking for easily.
          <br />- <b>Functionality Cookies:</b> These cookies are used to
          recognize you when you return to the Site. This enables us to
          personalize our content for you, greet you by name, and remember your
          preferences (for example, your choice of language or region).
          <br />- <b>Targeting/Advertising Cookies:</b> These cookies are used
          to track your browsing habits and to deliver targeted advertisements
          based on your interests. They are also used to limit the number of
          times you see an advertisement and to measure the effectiveness of
          advertising campaigns.
        </p>
        <h3>Your Choices Regarding Cookies</h3>
        <p>
          - You can control and/or delete cookies as you wish. You can delete
          all cookies that are already on your computer and you can set most
          browsers to prevent them from being placed. If you do this, however,
          you may have to manually adjust some preferences every time you visit
          the Site and some services and functionalities may not work.
          <br />- For more information about cookies and how to manage them,
          please visit www.allaboutcookies.org.
        </p>

        <h3>Changes to This Cookie Policy</h3>
        <p>
          We may update this Cookie Policy from time to time to reflect changes
          in our practices or applicable laws. We will notify you of any
          material changes by posting the updated Cookie Policy on the Site.
        </p>
        <h3>Contact Us</h3>
        <p>
          If you have any questions or concerns about this Cookie Policy or our
          use of cookies, please contact us at info@benjamiin.com.
        </p>
      </div>
    </>
  )
}
