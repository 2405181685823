import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/fontawesome-free-solid'

export default function TitleCenter({
  title,
  content,
  cta,
  ctaAlt,
  ctaLink,
  ctaAltLink,
}) {

  return (
    <>
      <div
        id="module-text-center"
        className="w-full flex items-center max-w-3xl px-5 md:p-0 mx-auto"
      >
        <div className="w-full py-0  mx-auto text-center">
          <div className="mb-[0px] text-center md:text-center">
            <h2 className="hero-title">
              <div dangerouslySetInnerHTML={{ __html: title }}></div>
            </h2>
          </div>
          {content && (
            <div className="hero-subtitle mt-[15px] text-center md:text-center">
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
          )}
        </div>
        {cta && (
          <div className="w-full flex flex-wrap md:flex-block mt-[30px]">
            <div className="">
              <Link to={ctaLink}>
                <button className="primary-btn">
                  {cta}
                  <FontAwesomeIcon className="ms-3" icon={faArrowCircleRight} />
                </button>
              </Link>
            </div>
          </div>
        )}
        {ctaAlt && (
          <div className="w-full flex flex-wrap md:flex-block mt-[30px]">
            <div className="">
              <Link to={ctaAltLink}>
                <button className="primary-btn">
                  {ctaAlt}
                  <FontAwesomeIcon className="ms-3" icon={faArrowCircleRight} />
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
