import React from 'react'
import { useTranslation } from 'react-i18next'

import HeroSimple from '../../../components/modules/hero-simple'

export default function Impressum() {
  const { t } = useTranslation()

  return (
    <>
      <HeroSimple
        title={`Impressum`}
        content={t('impressumContent')}
        cta={undefined}
        ctaLink={undefined}
        ctaAlt={undefined}
        ctaAltLink={undefined}
      />
    </>
  )
}
