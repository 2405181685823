import React from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'

import Hero from '../../../components/modules/hero'
import TextCenter from '../../../components/modules/text-center'
import { Helmet } from 'react-helmet'

export default function Approach() {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>{t('approachHeroTitle')}</title>
        <meta name="description" content={t('approachDescription')} />
        <meta property="og:title" content={t('approachHeroTitle')} />
        <meta property="og:description" content={t('approachDescription')} />
        {i18n.language === 'en' && (
          <meta property="og:url" content="https://benjamiin.com/en/approach" />
        )}
        {i18n.language === 'de' && (
          <meta property="og:url" content="https://benjamiin.com/de/ansatz" />
        )}
        {i18n.language === 'fr' && (
          <meta property="og:url" content="https://benjamiin.com/fr/approche" />
        )}
        <meta
          property="og:image"
          content="https://benjamiin.com/uploads/images/drawings/Benjamiin-drawing-120520248.jpg"
        />
        <meta property="og:type" content="page" />
      </Helmet>
      <Hero
        title={t('approachHeroTitle')}
        content={t('approachHeroContent')}
        background={
          'https://benjamiin.com/uploads/images/drawings/Benjamiin-drawing-120520248.jpg'
        }
        cta={undefined}
        ctaLink={undefined}
        ctaAlt={undefined}
        ctaAltLink={undefined}
      />

      <div className="max-w-6xl flex-wrap mx-auto w-full mb-[100px] sm:p-4">
        <div className="w-full mb-[50px]">
          <TextCenter
            title={t('approach1Title')}
            content={t('approach1Content')}
            icon={t('iconLoveBlack')}
            id={undefined}
          />
        </div>
        <div className="w-full mb-[50px]">
          <TextCenter
            title={t('approach2Title')}
            content={t('approach2Content')}
            icon={t('iconCelebrationBlack')}
            id={undefined}
          />
        </div>
        <div className="w-full mb-[50px]">
          <TextCenter
            title={t('approach3Title')}
            content={t('approach3Content')}
            icon={t('iconChoiceBlack')}
            id={undefined}
          />
        </div>
      </div>
    </>
  )
}
