import React from 'react'
import { useTranslation } from 'react-i18next'

import ProfileList from '../../../components/modules/profile-list'
import TitleCenter from '../../../components/modules/title-center'
import ReturnParameters from '../../../functions/return-parameters'

export default function Team() {
  ReturnParameters()
  const { t } = useTranslation()

  return (
    <>
      <div className="max-w-6xl w-full mt-[60px] mb-[100px] w-full flex flex-wrap mx-auto justify-center sm:p-0">
        <div className="w-full mx-auto">
          <TitleCenter
            title={t('teamTitle')}
            content={undefined}
            cta={undefined}
            ctaAlt={undefined}
            ctaLink={undefined}
            ctaAltLink={undefined}
          />
          <ProfileList />
        </div>
      </div>
    </>
  )
}
