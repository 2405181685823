import React from 'react'
import { Routes, Route } from 'react-router-dom'

import PublicLayout from '../../pages/public/public-layout.tsx'
import {
  Home,
  Books,
  Approach,
  ChampionsClub,
  Newsletter,
  NewsletterSuccess,
  NewsletterConfirmation,
  UnsubscribeConfirmation,
  Story,
  Contact,
  AboutUs,
  ContactConfirmation,
  Team,
  Sponsoring,
  Partnerships,
  Media,
  PrivacyPolicy,
  CookiePolicy,
  TermsAndConditions,
  Profile,
  Impressum,
  AboutBenjamiin,
  UnderConstruction,
  Error,
  Recipes,
} from '../public'

const PublicRoute = () => {
  return (
    <>
      <Routes>
        <Route element={<PublicLayout />}>
          <Route index element={<Home />} />
          <Route element={<Home />} path={'/:lng/home'} />
          <Route
            element={<UnderConstruction />}
            path={'/:lng/under-construction'}
          />
          <Route element={<Error />} path={'/:lng/error'} />
          <Route element={<Books />} path={'/:lng/books'} />
          <Route element={<Books />} path={'/:lng/livres'} />
          <Route element={<Books />} path={'/:lng/audio'} />
          <Route element={<Approach />} path={'/:lng/approach'} />
          <Route element={<Approach />} path={'/:lng/approche'} />
          <Route element={<Approach />} path={'/:lng/ansatz'} />
          <Route element={<ChampionsClub />} path={'/:lng/champions-club'} />
          <Route element={<Newsletter />} path={'/:lng/newsletter'} />
          <Route
            element={<Newsletter />}
            path={'/:lng/inscription-a-la-newsletter'}
          />
          <Route element={<Newsletter />} path={'/:lng/newsletter-signup'} />
          <Route element={<Newsletter />} path={'/:lng/newsletter-anmeldung'} />
          <Route
            path={'/:lng/newsletter-success'}
            element={<NewsletterSuccess />}
          />
          <Route
            element={<NewsletterConfirmation />}
            path={'/:lng/newsletter-confirmation/*'}
          />
          <Route
            path={'/:lng/unsubscribe-confirmation/*'}
            element={<UnsubscribeConfirmation />}
          />
          <Route element={<Story />} path={'/:lng/stories'} />
          <Route element={<Story />} path={'/:lng/geschichte'} />
          <Route element={<Story />} path={'/:lng/histoires'} />
          <Route element={<Contact />} path={'/:lng/contact-us'} />
          <Route element={<Contact />} path={'/:lng/contact'} />
          <Route element={<Contact />} path={'/:lng/kontakt'} />

          <Route element={<AboutUs />} path={'/:lng/about-us'} />
          <Route element={<AboutUs />} path={'/:lng/ueber-uns'} />
          <Route element={<AboutUs />} path={'/:lng/a-propos-de-nous'} />
          <Route
            element={<ContactConfirmation />}
            path={'/:lng/contact-confirmation'}
          />

          <Route element={<Team />} path={'/:lng/our-team'} />
          <Route element={<Team />} path={'/:lng/unser-team'} />
          <Route element={<Team />} path={'/:lng/notre-equipe'} />

          <Route path={'/:lng/sponsoring'} element={<Sponsoring />} />
          <Route element={<Partnerships />} path={'/:lng/partenariat'} />
          <Route element={<Partnerships />} path={'/:lng/partnership'} />
          <Route element={<Partnerships />} path={'/:lng/partnerships'} />
          <Route element={<Partnerships />} path={'/:lng/partnerschaft'} />
          <Route element={<Media />} path={'/:lng/media-corner'} />
          <Route element={<Media />} path={'/:lng/espace-media'} />
          <Route element={<Media />} path={'/:lng/medienbereich'} />
          <Route element={<PrivacyPolicy />} path={'/:lng/privacy-policy'} />
          <Route element={<PrivacyPolicy />} path={'/:lng/datenschutz'} />
          <Route
            element={<PrivacyPolicy />}
            path={'/:lng/protection-des-donnees'}
          />
          <Route element={<CookiePolicy />} path={'/:lng/cookie-richtlinie'} />
          <Route element={<CookiePolicy />} path={'/:lng/cookie-policy'} />
          <Route
            element={<CookiePolicy />}
            path={'/:lng/politique-de-cookies'}
          />
          <Route
            element={<TermsAndConditions />}
            path={'/:lng/conditions-generales'}
          />
          <Route
            element={<TermsAndConditions />}
            path={'/:lng/terms-and-conditions'}
          />
          <Route
            element={<TermsAndConditions />}
            path={'/:lng/allgemeine-geschaftsbedingungen'}
          />

          <Route element={<Profile />} path="/:lng/profile/*" />

          <Route element={<Impressum />} path={'/:lng/impressum'} />
          <Route element={<Impressum />} path={'/:lng/legal-notice'} />
          <Route element={<Impressum />} path={'/:lng/donnees-legales'} />

          <Route element={<AboutBenjamiin />} path={'/:lng/about-benjamiin'} />
          <Route element={<AboutBenjamiin />} path={'/:lng/ueber-banjamiin'} />
          <Route
            element={<AboutBenjamiin />}
            path={'/:lng/a-propos-de-benjamiin'}
          />
          <Route element={<Recipes />} path={'/:lng/recipes'} />
          <Route element={<Recipes />} path={'/:lng/rezepte'} />
          <Route element={<Recipes />} path={'/:lng/recettes'} />

          {/* 
          
        TEST
        <Route path="/:lng/test-new" element={<TestNew />} 
      />
        <Route path="/:lng/shop-new" element={<Shop />} 
      />
        <Route path="/:lng/modules" element={<Modules />} 
      />
        <Route path="/:lng/test" element={<TestAPI />} 
      />
        <Route path="/:lng/test2" element={<Test />} 
      />
        <Route path="/:lng/home-test" element={<HomeTest />} 
      />
        <Route path="/:lng/pdf" element={<OfficialDoc />} 
      />

          <Route
                          path="/:lng/purchase-summary"
                          element={<PurchaseSummary />}
                        />
          <Route path="/:lng/cart" element={<Shopcart />} 
                      />
          <Route
                          path="/:lng/purchase-details"
                          element={<PurchaseDetails />}
                        />
                        
                        <Route
                          element={<ShopProductPage />}
                          path="/:lng/product/*"
                        />



*/}
          <Route path={'*'} element={<Error />} />
        </Route>
      </Routes>
    </>
  )
}

export default PublicRoute
