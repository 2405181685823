import React from 'react'
import i18n from '../../i18n'
import CardSmall from './card-small'

export default function TitleAndCards({ title, content }) {


  return (
    <>
      <div className="w-full flex flex-wrap items-center max-w-6xl">
        <div className="w-full flex flex-wrap justify-center">
          {title && (
            <div className="w-full flex flex-wrap justify-center items-center max-w-2xl">
              <h2 className="hero-title !text-center mb-[20px]">{title}</h2>
            </div>
          )}
          {content && (
            <div className="w-full flex flex-wrap items-center max-w-2xl text-center text-[#55]">
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
          )}
        </div>
        <div className="w-full flex flex-wrap items-center justify-center max-w-6xl mx-auto mt-10 mb-5">
          <CardSmall
            category={'food'}
            title={'Another recipe'}
            price={'CHF 20.00'}
            image={
              'https://images.pexels.com/photos/1640772/pexels-photo-1640772.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
            }
            link={`/${i18n.language}/recipe`}
          />
          <CardSmall
            category={'food'}
            title={'Another recipe'}
            price={'CHF 20.00'}
            image={
              'https://images.pexels.com/photos/1640772/pexels-photo-1640772.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
            }
            link={`/${i18n.language}/recipe`}
          />
          <CardSmall
            category={'food'}
            title={'Another recipe'}
            price={'CHF 20.00'}
            image={
              'https://images.pexels.com/photos/1640772/pexels-photo-1640772.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
            }
            link={`/${i18n.language}/recipe`}
          />
        </div>
      </div>
    </>
  )
}
